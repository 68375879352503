<div class="fondo-astara">

    <div class="hide">
        <p>Tiempo transcurrido: {{ tiempoFormateado }} segundos</p>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="titulo-intro">
                    <img class="w-full-img-100" src="assets/img_astara/common/titular@2x.png" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="container indicaciones">
        <div class="row">
            <div class="col-md-12">
                <div class="item-trivia-indicacion">
                    <p>Durante 2 semanas, cada martes y jueves lanzaremos dos nueva preguntas relacionadas a Astara Platform. Para que no te olvides de participar, semanalmente te estaremos enviando un recordatorio vía e-mail.</p>
                </div>
                <div class="item-trivia-indicacion">
                    <p>Tendrás que ingresar con tu cuenta de correo Astara (@astara), en caso de no poder ingresar escríbenos al correo: <a href="mailto:astara.platform.pe@astara.com"><span class="style-email">astara.platform.pe@astara.com</span></a></p>
                </div>
                <div class="item-trivia-indicacion">
                    <p>Por cada pregunta, habrá 1 respuesta válida, entre 3 alternativas posibles. Ganarás puntos según la siguiente tabla:</p>
                </div>

                <div class="container tabla-premios">
                    <div class="row">
                        <div class="col-md-12">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                  <tr>
                                    <td style="
                                    background-color:#FAB42D;
                                    border-right: none;
                                    font-weight: 700;
                                    text-align:center;
                                    padding:10px; 
                                    font-size:20px;">
                                    Intentos</td>
                                    <td style="
                                    background-color:#FAB42D;
                                    border-left: none; 
                                    font-weight: 700; 
                                    text-align:center;
                                    padding:10px; 
                                    font-size:20px;">Puntos</td>
                                  </tr>
                                  <tr>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">1er intento</td>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">5 puntos</td>
                                  </tr>
                                  <tr>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">2do intento</td>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">3 puntos</td>
                                  </tr>
                                  <tr>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">3er intento</td>
                                    <td style="font-size:20px; text-align:center; background:#FFFFFF; padding:10px;">1 punto</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

                <div class="item-trivia-indicacion">
                    <p>Semana a semana irás acumulando los puntos obtenidos. La puntuación mínima total será de 8 y la máxima de 40.</p>
                </div>
                <div class="item-trivia-indicacion">
                    <p>Al finalizar las 8 preguntas de la TRIVIA ASTARA PLATFORM ya estarás participando para ganar uno de los premios que sortearemos.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="grafica-trivia">
                    <img class="w-full-img-100" src="assets/img_astara/common/grafica@2x.png" alt="Experiencia BigBox">
                </div>
            </div>
        </div>
    </div>

    <div class="container contenido-trivia-boton">
        <div class="row">
            <div class="col-md-12">
                <a class="boton-enviar jugar cursor-pointer" (click)="quieroJugar()">¡Quiero jugar!</a>
            </div>
        </div>
    </div>
</div>
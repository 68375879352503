<div class="container-fluid">
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row" id="usuarios">
            <div class="col-sm-12">
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-header border_astara_p">
                            <div class="row align-items-center">
                                <div class="col-lg-9">
                                    <!--div class="row align-items-center">
                                        <div class="col-md-2 pl-0">
                                            <div class="form-group mb-0">
                                                <select class="form-select" id="select_filtros" (change)="onChangeFiltro($event.target.value)" required>
                                                    <option value="">Filtrar por</option>
                                                    <option value="question">Nombres</option>
                                                    <option value="review">Apellidos</option>
                                                    <option value="available_date">Cargo</option>
                                                    <option value="state">Estado</option>
                                                    <option value="created_at">Creación</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 p-0">
                                            <input type="text" class="form-control class_addinput" (change)="inputSearch($event)" id="input_search" placeholder="Buscar..." required [disabled]="disabled_insearch" />
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group mb-0">
                                                <select class="form-select" (change)="ascDescFilter($event)" id="select_orden" required [disabled]="disabled_insearch">
                                                    <option value="">Orden</option>
                                                    <option value="ASC">Ascendente</option>
                                                    <option value="DESC">Descendente</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 p-0">
                                            <button class="col-lg-12 btn btn-primary btn-astara-filtros btn-restablecer-astara" placement="top" ngbTooltip="Restablecer los filtros" (click)="btn_search();getItems();"><i data-feather="rotate-ccw"></i></button>
                                        </div>
                                    </div-->
                                </div>
                                <div class="col-lg-3">
                                    <div class="row justify-content-right">
                                        <!--button class="btn btn-success btn-astara-filtros" placement="top" ngbTooltip="Añade un nuevo ítem" (click)="open(content);clearInputs();"><i data-feather="plus"></i></button-->
                                        <button type="button" class="btn btn-astara btn-astara-filtros mr-2" placement="top" ngbTooltip="Descargar fichero" placement="top" ngbTooltip="Descargar fichero" (click)="exportAsExcelFile();"><i data-feather="arrow-down-circle"></i></button>
                                        <button type="button" class="btn btn-primary btn-astara-filtros mr-2" placement="top" ngbTooltip="Importa un archivo json en el formato enviado, asegúrate que la data sea correcta."><input type="file" accept=".json" id="inputFileImportHideQuestions" (change)="onFileSelected($event)"><i data-feather="arrow-up-circle"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Card header end-->
                    </div>
                </div>
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-body">
                            <div class="col-sm-12 col-xl-12">
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [title]="item0.available_date" *ngFor="let item0 of tableItemsExport" [id]="item0.available_date">
                                        <ng-template ngbPanelContent>
                                            <!--    start content-->
                                            <div class="col-sm-12 col-xl-12">
                                                <div class="card border_astara_p">
                                                    <div class="">
                                                        <a class="col-lg-12 d-flex mb-2" href="javascript:void(0)">
                                                            <div class="col-lg-8 align-items-center d-flex p-2-0 text-dark fw-bold">Nombre</div>
                                                            <div class="col-lg-2 align-items-center d-flex p-2-0 pl-5 text-dark fw-bold">Estado</div>
                                                            <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Editar</div>
                                                            <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Eliminar</div>
                                                        </a>
                                                        <div class="list-group">
                                                            <a class="list-group-item list-group-item-action col-lg-12 d-flex" href="javascript:void(0)" *ngFor="let item of item0.preguntas">
                                                                <div class="col-lg-8">
                                                                    <h6><strong (click)="copyText(item.question)">{{ item.question }}</strong></h6>
                                                                    <p class="col-lg-12 text-right" (click)="copyText(item.available_date)"><strong>{{ item.available_date }} </strong> {{ item.start_time }} - {{ item.end_time }}</p>
                                                                    <div class="col-lg-12 item_options" *ngFor="let item_options of item.options">
                                                                        <div class="row">
                                                                            <div class="col-lg-10" (click)="copyText(item_options.option)">{{ item_options.option }}</div>
                                                                            <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                                                                <span *ngIf="item_options.correct == 1" class="badge badge-success text-white state_astara">SI</span>
                                                                                <span *ngIf="item_options.correct == 0" class="badge badge-danger text-white state_astara">NO</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                                                    <span *ngIf="item.state == 1" class="badge badge-success text-white state_astara">Activo</span>
                                                                    <span *ngIf="item.state == 0" class="badge badge-danger text-white state_astara">Inactivo</span>
                                                                </div>
                                                                <div class="col-lg-1 text-center">
                                                                    <button class="background_border__none" (click)="edit(item);open(content);"><app-feather-icons class="text-primary" icon="settings"></app-feather-icons></button>
                                                                </div>
                                                                <div class="col-lg-1 text-center">
                                                                    <button class="background_border__none" (click)="onDelete(item)"><app-feather-icons class="text-danger" icon="trash-2"></app-feather-icons></button>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end content-->
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                            <div class="d-flex justify-content-between p-2">
                                <ngb-pagination class="mt-4" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getPremiumDataJSON();"> </ngb-pagination>
                                <select class="custom-select" style="width: auto; border: none;" [(ngModel)]="pageSize" (ngModelChange)="getPremiumDataJSON();">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="200">200</option>
                                </select>
                            </div>
                            <!--div class="d-flex justify-content-between p-2">
                                <ngb-pagination [collectionSize]="pagination.total" [pageSize]="pagination.per_page" [(page)]="page" [maxSize]="5" [rotate]="true"
                                    [ellipses]="false" [boundaryLinks]="true" (pageChange)="getPremiumData2()"></ngb-pagination>
                                <select class="custom-select" style="width: auto; border: none;" [(ngModel)]="pageSize" (ngModelChange)="getPremiumData2();">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="200">200</option>
                                </select>
                            </div-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.tabla -->
            <!-- Modal user  -->
            <ng-template #content let-modal lg>
                <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                    <div class="modal-header">
                        <div>
                            <h4 class="modal-title mb-0" id="modal-basic-title">Pregunta</h4>
                            <p class="mb-0 text-gray fw-light">{{ title_form }}</p>
                        </div>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');actionClose()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="question"><strong>Pregunta</strong></label>
                                        <input formControlName="question" type="text" class="form-control" id="question" placeholder="Pregunta" />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="review"><strong>Review</strong></label>
                                        <input formControlName="review" type="text" class="form-control" id="review" placeholder="Review" />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="help"><strong>Texto de ayuda</strong></label>
                                        <input formControlName="help" type="text" class="form-control" id="help" placeholder="Texto de ayuda" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="available_date"><strong>Fecha disponible</strong></label>
                                        <input formControlName="available_date" type="date" class="form-control" id="available_date" placeholder="Fecha disponible"  placement="top" ngbTooltip="Esta pregunta solo estará disponible desde esta fecha"/>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="available_date_end"><strong>Fecha fin</strong></label>
                                        <input formControlName="available_date_end" type="date" class="form-control" id="available_date_end" placeholder="Fecha fin"  placement="top" ngbTooltip="Esta pregunta solo estará disponible hasta esta fecha"/>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="start_time"><strong>Hora de inicio</strong></label>
                                        <input formControlName="start_time" type="time" class="form-control" id="start_time" placeholder="Hora de inicio" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="end_time"><strong>Hora de fin</strong></label>
                                        <input formControlName="end_time" type="time" class="form-control" id="end_time" placeholder="Hora de fin" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <label for="state"><strong>Estado</strong></label>
                                    <select class="form-select" formControlName="state" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!--button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');clearInputs();actionClose()">Cerrar</button-->
                        <button type="submit" class="btn btn-dark">{{ text_btn }} Pregunta</button>
                    </div>
                </form>
                <div class="separator"></div>
                <form [formGroup]="formOptions" (ngSubmit)="onSubmitOptions(form.value)" action="">
                    <div class="modal-header">
                        <div>
                            <h4 class="modal-title mb-0" id="modal-basic-title">Opciones</h4>
                            <p class="mb-0 text-gray fw-light">Edita las opciones de esta pregunta</p>
                        </div>
                    </div>
                    <div class="modal-body">
                        <a class="col-lg-12 d-flex" href="javascript:void(0)">
                            <div class="col-lg-6 align-items-center d-flex p-2-0 text-dark fw-bold">Texto Opción</div>
                            <div class="col-lg-2 align-items-center d-flex p-2-0 pl-5 text-dark fw-bold">Correcto</div>
                            <div class="col-lg-2 align-items-center d-flex p-2-0 pl-5 text-dark fw-bold">Estado</div>
                            <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Editar</div>
                            <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Eliminar</div>
                        </a>
                        <div class="list-group">
                            <a class="list-group-item list-group-item-action col-lg-12 d-flex" href="javascript:void(0)" *ngFor="let item of listadoOpciones">
                                <div class="col-lg-6" (click)="copyText(item.option)">{{ item.option }}</div>
                                <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                    <span *ngIf="item.correct == 1" class="badge badge-success text-white state_astara">SI</span>
                                    <span *ngIf="item.correct == 0" class="badge badge-danger text-white state_astara">NO</span>
                                </div>
                                <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                    <span *ngIf="item.state == 1" class="badge badge-success text-white state_astara">Activo</span>
                                    <span *ngIf="item.state == 0" class="badge badge-danger text-white state_astara">Inactivo</span>
                                </div>
                                <div class="col-lg-1 text-center">
                                    <button class="background_border__none" (click)="editOption(item);" type="button"><app-feather-icons class="text-primary" icon="settings"></app-feather-icons></button>
                                </div>
                                <div class="col-lg-1 text-center">
                                    <button class="background_border__none" (click)="onDeleteOptions(item)" type="button"><app-feather-icons class="text-danger" icon="trash-2"></app-feather-icons></button>
                                </div>
                            </a>
                        </div>
                        <div class="separator"></div>
                        <div class="col-md-12 col-lg-12 mt-4">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="option"><strong>Opción</strong></label>
                                        <input formControlName="option" type="text" class="form-control" id="option" placeholder="Opción"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="correct"><strong>¿Correcto?</strong></label>
                                    <select class="form-select" formControlName="correct" id="correct" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Sí</option>
                                        <option value="0">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="state_option"><strong>Estado</strong></label>
                                    <select class="form-select" formControlName="state" id="state_option" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!--button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');clearInputs();actionClose()">Cerrar</button-->
                        <button type="submit" class="btn btn-dark">Guardar Opcion</button>
                    </div>
                </form>
            </ng-template>
            <!-- Modal user  -->
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>


import { Component, OnInit, HostListener } from '@angular/core';

import { AsesoresService } from '../../../__servicios/service.index';
import { BaseComponent } from '../../../base.component';
import { AuthService } from '../../../shared/services/firebase/auth.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent extends BaseComponent implements OnInit {
  

  constructor(public _service:AsesoresService, public authService: AuthService) {super(_service); }
  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:popstate')

  onBeforeUnload(event: Event) {
    // Cancela el evento de recarga de página
    event.preventDefault();
      this.authService.SignOut();
    // Muestra un mensaje de confirmación al usuario
    //event.returnValue = '¿Estás seguro de que deseas abandonar esta página? Los cambios no guardados se perderán.';
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    //this.stopCronometro();
  }
}

<div *ngIf="userRoleLevel == 1; else showNotUser" class="container-fluid">
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row" id="usuarios">
            <div class="col-sm-12">
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-header border_astara_p">
                            <div class="row align-items-center">
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-md-2 pl-0">
                                            <div class="form-group mb-0">
                                                <select class="form-select" id="select_filtros" (change)="onChangeFiltro($event.target.value)" required>
                                                    <option value="">Filtrar por</option>
                                                    <option value="name">Nombres</option>
                                                    <option value="email">Email</option>
                                                    <option value="role">Rol</option>
                                                    <option value="state">Estado</option>
                                                    <option value="created_at">Creación</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 p-0">
                                            <input type="text" class="form-control class_addinput" (change)="inputSearch($event)" id="input_search" placeholder="Buscar..." required [disabled]="disabled_insearch" />
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group mb-0">
                                                <select class="form-select" (change)="ascDescFilter($event)" id="select_orden" required [disabled]="disabled_insearch">
                                                    <option value="">Orden</option>
                                                    <option value="ASC">Ascendente</option>
                                                    <option value="DESC">Descendente</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 p-0">
                                            <button class="col-lg-12 btn btn-primary btn-astara-filtros btn-restablecer-astara" placement="top" ngbTooltip="Restablecer los filtros" (click)="btn_search();getItems();"><i data-feather="rotate-ccw"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="row justify-content-right">
                                        <button type="button" class="btn btn-primary btn-astara-filtros mr-2" placement="top" ngbTooltip="Importa un archivo excel en el formato enviado, asegúrate que la data sea correcta."><input type="file" id="inputFileImportHide" (change)="onFileSelected($event)"><i data-feather="arrow-up-circle"></i></button>
                                        <button class="btn btn-success btn-astara-filtros" placement="top" ngbTooltip="Añade un nuevo ítem" (click)="open(createUser);clearInputs();"><i data-feather="plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Card header end-->
                    </div>
                </div>
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-body">
                            <a class="col-lg-12 d-flex" href="javascript:void(0)">
                                <div class="col-lg-5 align-items-center d-flex p-2-0 text-dark fw-bold">Nombres</div>
                                <div class="col-lg-3 align-items-center d-flex p-2-0 text-dark fw-bold">Correo</div>
                                <div class="col-lg-2 align-items-center d-flex p-2-0 pl-5 text-dark fw-bold">Estado</div>
                                <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Editar</div>
                                <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Eliminar</div>
                            </a>
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action col-lg-12 d-flex" href="javascript:void(0)" *ngFor="let item of tableItems">
                                    <div class="col-lg-5" (click)="copyText(item.name)"><!--img class="col-lg-2 mr-3" [src]="item.image" alt="{{ item.name }}" /--> {{ item.name }}</div>
                                    <div class="col-lg-3 align-items-center d-flex" (click)="copyText(item.email)">{{ item.email }}</div>
                                    <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                        <span *ngIf="item.state == 1" class="badge badge-success text-white state_astara">Activo</span>
                                        <span *ngIf="item.state == 0" class="badge badge-danger text-white state_astara">Inactivo</span>
                                    </div>
                                    <div class="col-lg-1 text-center">
                                        <button class="background_border__none" (click)="edit(item);open(content);"><app-feather-icons class="text-primary" icon="settings"></app-feather-icons></button>
                                    </div>
                                    <div class="col-lg-1 text-center">
                                        <button class="background_border__none" (click)="onDelete(item)"><app-feather-icons class="text-danger" icon="trash-2"></app-feather-icons></button>
                                    </div>
                                </a>
                            </div>
                            <div class="d-flex justify-content-between p-2">
                                <ngb-pagination [collectionSize]="pagination.total" [pageSize]="pagination.per_page" [(page)]="page" [maxSize]="5" [rotate]="true"
                                    [ellipses]="false" [boundaryLinks]="true" (pageChange)="getPremiumData2()"></ngb-pagination>
                                <select class="custom-select" style="width: auto; border: none;" [(ngModel)]="pageSize" (ngModelChange)="getPremiumData2();">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="200">200</option>
                                </select>
                            </div>
                            <!--div class="d-flex justify-content-between p-2">
                                <ngb-pagination class="mt-4" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getPremiumData();"> </ngb-pagination>
                                <select class="custom-select" style="width: auto; border: none;" [(ngModel)]="pageSize" (ngModelChange)="getPremiumData();">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="200">200</option>
                                </select>
                            </div-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.tabla -->
            <!-- Modal user  -->
            <ng-template #content let-modal lg>
                <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                    <div class="modal-header">
                        <div>
                            <h4 class="modal-title mb-0" id="modal-basic-title">Usuarios</h4>
                            <p class="mb-0 text-gray fw-light">{{ title_form }}</p>
                        </div>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');actionClose()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="name"><strong>Nombre</strong></label>
                                        <input formControlName="name" type="text" class="form-control" id="name" placeholder="Nombre" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="lastname"><strong>Apellidos</strong></label>
                                        <input formControlName="lastname" type="text" class="form-control" id="lastname" placeholder="Apellidos" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="email"><strong>Email</strong></label>
                                        <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="cargo_us"><strong>Cargo</strong></label>
                                        <input formControlName="cargo_us" type="text" class="form-control" id="cargo_us" placeholder="Cargo" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="role"><strong>Rol</strong></label>
                                        <select class="form-control" formControlName="role" id="role" required>
                                            <option value="">- Seleccionar -</option>
                                            <option value="1">Acceso total</option>
                                            <option value="2">Acceso medio</option>
                                            <option value="3">Acceso limitado</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <label for="state"><strong>Estado</strong></label>
                                    <select class="form-control" formControlName="state" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-12 mb-2" *ngIf="showUpPass">
                                    <ngb-accordion (panelChange)="beforeChange($event)">
                                        <ngb-panel id="preventchange-1" title="Actualizar contraseña">
                                            <ng-template ngbPanelContent>
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <div class="form-group">
                                                            <label for="password"><strong>Password</strong></label>
                                                            <input type="password" class="form-control" id="password" placeholder="Password" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-lg-4 align-items-center d-flex pt-2">
                                                        <button (click)="updatePassword();" type="button" class="btn btn-dark">Actualizar contraseña</button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!--button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');clearInputs();actionClose()">Cerrar</button-->
                        <button type="submit" class="btn btn-dark">{{ text_btn }}</button>
                    </div>
                </form>
            </ng-template>
            <!-- Modal user  -->

            <!-- Modal create user  -->
            <ng-template #createUser let-modal lg>
                <form [formGroup]="form_create" (ngSubmit)="onSubmitCreate(form_create.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Crear usuario</h4>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');actionClose()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="cod_asesor"><strong>Asesor</strong></label>
                                    <select class="form-select" formControlName="cod_asesor" (change)="onChangeAsesor($event.target.value)" required>
                                      <option value ="">- Asesor -</option>
                                      <option *ngFor="let item of arrAsesores" [value]="item.cod_asesor">{{ item.nom_asesor }}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="name"><strong>Nombre</strong></label>
                                        <input formControlName="name" type="text" class="form-control" id="name" placeholder="Nombre" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="lastname"><strong>Apellidos</strong></label>
                                        <input formControlName="lastname" type="text" class="form-control" id="lastname" placeholder="Apellidos" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="email"><strong>Email</strong></label>
                                        <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="role"><strong>Rol</strong></label>
                                        <select class="form-control" formControlName="role" id="role" required>
                                            <option value="">- Seleccionar -</option>
                                            <option value="1">Acceso total</option>
                                            <option value="2">Acceso medio</option>
                                            <option value="3">Acceso limitado</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="form-group">
                                        <label for="password"><strong>Password</strong></label>
                                        <input formControlName="password" type="text" class="form-control" id="password" placeholder="Password" minlength="6" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <label for="state"><strong>Estado</strong></label>
                                    <select class="form-control" formControlName="state" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <!--div class="col-md-6 col-xl-3 xl-40 upload-space-sm">
                                            Multiple:
                                            <input type="file" ng2FileSelect [uploader]="uploader" multiple /><br />
                                            Single:
                                            <input type="file" ng2FileSelect [uploader]="uploader" class="mb-0" />
                                        </div-->
                                        <div class="">
                                            <!--p>Queue length: {{ uploader?.queue?.length }}</p-->
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th width="30%">Nombre</th>
                                                            <th>Estatus</th>
                                                            <th>Acción</th>
                                                            <th></th> <th></th> <th></th> <th></th> <th></th> <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="border-b-0" *ngFor="let item of uploader.queue">
                                                            <td><strong>{{ item?.file?.name }}</strong></td>
                                                            <td *ngIf="uploader.isHTML5">
                                                                <div class="progress mb-0">
                                                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span *ngIf="item.isSuccess"><i class="fa fa-check-square-o text-astara"></i></span>
                                                                <span *ngIf="item.isError"><i class="fa fa-trash text-danger"></i></span>
                                                            </td>
                                                            <td nowrap>
                                                                <button type="button" class="btn btn-success btn-xs me-1 btnastaraUpload" (click)="item.upload(); verifyUploader()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                                                    <div>
                                                                        Upload <span class="fa fa-upload"></span>
                                                                    </div>
                                                                </button>
                                                                <button type="button" class="btn btn-danger btn-xs btnastaraUpload" (click)="item.remove()">
                                                                    <div>
                                                                        Eliminar <span class="fa fa-trash-o"></span>
                                                                    </div>
                                                                </button>
                                                            </td><!--{{ item?.file?.size/1024/1024 | number:'.2' }} MB-->
                                                            <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-12 mt-4">
                                                <div class="row">
                                                    <div class="fill-progress col-lg-8">
                                                        <div class="progress border-radius0">
                                                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <img id="imagePreview" class="col-lg-12" src="https://staging.kia.com.pe/systemv1/cms/assets/img/image_default.jpg"  alt="">
                                                    </div>
                                                </div>
                                                <!--button type="button" class="btn btn-success btn-s me-1" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                                                    <span class="fa fa-upload"></span> Upload
                                                </button>
                                                <button type="button" class="btn btn-danger btn-s" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                                                    <span class="fa fa-trash"></span> Remove all
                                                </button-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');clearInputs();">Cerrar</button>
                        <button type="submit" class="btn btn-dark">Guardar</button>
                    </div>
                </form>
            </ng-template>
            <!-- Modal create user  -->
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>

<!--error-401 start-->
<ng-template #showNotUser>
    <div class="error-wrapper">
        <div class="container">
            <div class="error-heading">
                <h2 class="headline font-primary">401</h2>
            </div>
            <img class="img-100" src="assets/images/other-images/sad.png" alt="" />
            <div class="col-md-8 offset-md-2">
                <p class="sub-content">Lo sentimos, tu nivel de usuario no tiene permiso para este módulo.</p>
            </div>
            <div class="">
                <a [routerLink]="'/dashboard/welcome'" class="btn btn-primary-gradien btn-lg">REGRESAR AL DASHBOARD</a>
            </div>
        </div>
    </div>
</ng-template>
<!--error-401 end-->

<ng-template #modelgallery let-modal id="modal_gallery">
    <div class="modal-header">
        <h4 class="modal-title">Gallery</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-gallery (onClear)="onSelectedProduct($event)"></app-gallery>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- Container-fluid Ends-->

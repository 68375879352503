
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLS_API } from '../constant';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })

export class QuestionsService extends BaseService {

    constructor(public http2: HttpClient) {
        super(http2);
    }

    //Obtenemos los datos
    getItems(order) {
        return this.http.get(URLS_API.questions + '/' + order, { headers: this.headers });
    }

    //search
    search(params: any, order) {
        console.log('search params', params)
        return this.http.get(URLS_API.questions + '/' + order, { headers: this.headers, params: params });
    }
    
    getItems2(cant, order, pagina) {
        return this.http.get(URLS_API.questions + '/' + cant + '/' + order + '?page=' + pagina, { headers: this.headers });
    }
    search2(params: any = [], cant, order, pagina) {
        params['page'] = pagina;
        console.log('search params', params)
        return this.http.get(URLS_API.questions + '/' + cant + '/' + order, { headers: this.headers, params: params });
    }

    //Creamos el item
    save(form: any) {
        return this.http.post(URLS_API.questions, form, { headers: this.headers });
    }

    //Update el item
    edit(form: any) {
        return this.http.put(URLS_API.questions + "/" + form.id, form, { headers: this.headers });
    }

    //Importamos data
    importar(data: any) {
        //this.headers.append('Accept', 'multipart/form-data');
        return this.http.post(URLS_API.questions, data, { headers: this.headersImage });
    }

    //ELiminamos
    delete(form: any) {
        return this.http.delete(URLS_API.questions + "/" + form.id, { headers: this.headers });
    }
}

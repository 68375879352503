import { Routes } from '@angular/router';
import { PerfilComponent } from '../../components/perfil/perfil.component';


import { UsuariosComponent } from '../../components/usuarios/usuarios.component';
import { AsesoresComponent } from '../../components/asesores/asesores.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ConteoComponent } from '../../components/trivia/conteo/conteo.component';
import { HomeComponent } from '../../components/trivia/home/home.component';
import { PreguntasComponent } from '../../components/trivia/preguntas/preguntas.component';
import { RespuestaComponent } from '../../components/trivia/respuesta/respuesta.component';
import { GraciasComponent } from '../../components/trivia/gracias/gracias.component';
import { RespuestasComponent } from '../../components/respuestas/respuestas.component';
import { PreguntasComponent as DashboardPreguntasComponent } from '../../components/preguntas/preguntas.component';

export const content: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      //breadcrumb: "Dashboard"
    }
  },
  {
    path: 'respuestas',
    component: RespuestasComponent,
    data: {
      title: 'Respuestas',
      breadcrumb: 'Respuestas'
    }
  },
  {
    path: 'questions',
    component: DashboardPreguntasComponent,
    data: {
      title: 'Preguntas',
      breadcrumb: 'Preguntas'
    }
  },
  {
    path: 'preguntas-datos',
    component: AsesoresComponent,
    data: {
      title: 'Preguntas',
      breadcrumb: 'Preguntas'
    }
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    children: [
      {
        path: '',
        data: {
          title: "Usuarios",
          breadcrumb: "Usuarios"
        }
      }
    ]
  }, 
  {
    path: 'editar-perfil',
    component: PerfilComponent,
    children: [
      {
        path: '',
        data: {
          title: "Perfil Usuario",
          breadcrumb: "Perfil Usuario"
        }
      }
    ]
  },
  {
    path: 'trivia',
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
        data: {
          title: 'Trivia',
          breadcrumb: 'Trivia'
        }
      },
      {
        path: 'welcome',
        component: HomeComponent,
        data: {
          title: 'Welcome',
          breadcrumb: 'Welcome'
        }
      },
      {
        path: 'conteo',
        component: ConteoComponent,
        data: {
          title: 'Conteo',
          breadcrumb: 'Conteo'
        }
      },
      {
        path: 'pregunta',
        component: PreguntasComponent,
        data: {
          title: 'Pregunta',
          breadcrumb: 'Pregunta'
        }
      },
      {
        path: 'review',
        component: RespuestaComponent,
        data: {
          title: 'Respuesta',
          breadcrumb: 'Respuesta'
        }
      },
      {
        path: 'gracias',
        component: GraciasComponent,
        data: {
          title: 'Gracias',
          breadcrumb: 'Gracias'
        }
      }
    ]
  }
];

import { Component, HostListener, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
declare var require; const Swal = require('sweetalert2');

var body = document.getElementsByTagName("body")[0];
import { BaseComponent } from '../../../base.component';
import { UsuariosService } from '../../../_servicios/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  public isOpenMobile: boolean = false;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public _service: UsuariosService,
    public navServices: NavService, public router: Router,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService) {
    super(_service);
    translate.setDefaultLang('en');
  }

  //@HostListener('window:beforeunload', ["$event"])
  //onUnload() {
    /*Swal.fire({
      title: 'Desea salir de la aplicacion?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Salir',
      denyButtonText: `No salir`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
    unload
    onunload
    beforeunload
    $(window).on('unload', function () {
      console.log('-------------------cerrar-----------------');
      window.localStorage.clear();
    })
    */
    /*window.onunload = function analytics(event) {
      window.localStorage.clear();
    };*/
    /*window.addEventListener("beforeunload", (evento) => {
      if (true) {
          evento.preventDefault();
          evento.returnValue = "";
          return "";
      }
    });
    window.onbeforeunload = function() {
      console.log('directo del doom')
    };*/
    //return false;
  //}
  
  clearCacheunloadPage(){
    window.localStorage.clear();
  }
  redirectPerfil(){
    this.router.navigate(['/dashboard/editar-perfil']);
    //window.location.href = '/editar-perfil';
  }

  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });

    /*  cronómetro */
    this.clock = this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date(JSON.parse(localStorage.getItem('cronometro'))); //new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');
      this.showDate();
    });
  }

  showDate(){
    let distance = this.end - this.now;
    this.day = Math.floor(distance / this._day);
    this.hours = Math.floor((distance % this._day) / this._hour);
    this.minutes = Math.floor((distance % this._hour) / this._minute);
    this.seconds = Math.floor((distance % this._minute) / this._second);
    
    //console.log('dataUsuario', this.dataUsuario);
    if(this.hours <= 0 && this.minutes <= 0){
      if(this.tiempoVencido && this.dataUsuario.email == "admin_trivia@kia.com.pe"){
        Swal.fire({
          title: 'Su sesión se venció',
          text: 'Por favor salga y vuelva entrar',
          //imageUrl: 'https://staging.kia.com.pe/systemv1/cms/assets/images/kia-logo.png',
          //imageWidth: 400,
          //imageHeight: 200,
          //imageAlt: 'Kia Logo',
        }).then((result) => {
          if (result.isConfirmed) {
            window.localStorage.clear();
            this.router.navigate(['/login']);
            location.reload();
          }
        })
        this.tiempoVencido = false;
      }
    }
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}


import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLS_API } from '../constant';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })

export class UserService extends BaseService {

    constructor(public http2: HttpClient) {
        super(http2);
    }

    //Obtenemos los datos
    getItems(order) {
        return this.http.get(URLS_API.user + '/' + order, { headers: this.headers });
    }

    
    getItems2(cant, order, pagina) {
        return this.http.get(URLS_API.user + '/' + cant + '/' + order + '?page=' + pagina, { headers: this.headers });
    }
    //search
    search(params: any, order) {
        console.log('search params', params)
        return this.http.get(URLS_API.user + '/' + order, { headers: this.headers, params: params });
    }
    search2(params: any = [], cant, order, pagina) {
        params['page'] = pagina;
        console.log('search params', params)
        return this.http.get(URLS_API.user + '/' + cant + '/' + order, { headers: this.headers, params: params });
    }

    //search
    search_user(params: any, order) {
        console.log('search params', params)
        return this.http.get(URLS_API.user + '/' + order, { headers: this.headers, params: params });
    }
    getSearch_export(start, end) {
        let params = {
            "start": start + ' 00:00:00',
            "end": end + ' 00:00:00'
        }
        //return this.http.post(URLS_API.search, params, { headers: this.headers });
        return this.http.post(URLS_API.user, params, { headers: this.headers });/**
        return this.http.post(URLS_API.search_export, params, { headers: this.headers }).pipe(
            map((res) => res)); */
    }

    //Creamos el item
    save(form: any) {
        return this.http.post(URLS_API.register, form, { headers: this.headers });
    }

    //Creamos el item
    saveCreate(form: any) {
        this.http.post(URLS_API.register, form, { headers: this.headers });
    }

    //Update el item
    edit(form: any) {
        return this.http.put(URLS_API.us_perfil + "/" + form.id, form, { headers: this.headers });
    }

    editPass(form: any) {
        this.http.put(URLS_API.us_pass + "/" + form.id, form, { headers: this.headers }).subscribe((res: any) => {
            if (res.state == 1) {
                console.log('us_pass ', res);
            } else {
                console.log('us_pass');
            }
        });
    }

    importDataExcel(form: any){
        return this.http.post(URLS_API.user, form, { headers: this.headers });
    }

    //ELiminamos
    delete(form: any) {
        return this.http.delete(URLS_API.user_delete + "/" + form.id, { headers: this.headers });
    }
}

export const APPLICATION_CONST = {
    name: 'ASTARA',
    //webservice: 'http://127.0.0.1:8000/api',
    webservice: '//trivia.juntosastaraplatform.pe/api/api',
    nombreCliente: 'ASTARA'
};
export const URLS_API = {
    login: APPLICATION_CONST.webservice + '/login',
    register: APPLICATION_CONST.webservice + '/register',
    logout: APPLICATION_CONST.webservice + '/logout',
    user: APPLICATION_CONST.webservice + '/user', 
    us_perfil: APPLICATION_CONST.webservice + '/us-perfil',
    us_pass: APPLICATION_CONST.webservice + '/us-pass',
    user_delete: APPLICATION_CONST.webservice + '/user-delete',
    get_user: APPLICATION_CONST.webservice + '/get-user',
    asesores: APPLICATION_CONST.webservice + '/asesores',
    
    trivia: APPLICATION_CONST.webservice + '/trivia',
    questions: APPLICATION_CONST.webservice + '/questions',
    options: APPLICATION_CONST.webservice + '/options',
    logins: APPLICATION_CONST.webservice + '/logins',
    answers: APPLICATION_CONST.webservice + '/answers',
    answers_respuestas: APPLICATION_CONST.webservice + '/answers-respuestas',
    answers_iduser: APPLICATION_CONST.webservice + '/answers-iduser',
    answers_users: APPLICATION_CONST.webservice + '/answers-users',
    prom_answers: APPLICATION_CONST.webservice + '/prom-answers',
    questions_importar: APPLICATION_CONST.webservice + '/questions-importar',
};

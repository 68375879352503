<div class="container-fluid">
  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="row" id="perfil-data">
      <div class="card border_astara_p">
        <div class="col-lg-12 text-center">
          <img class="col-lg-6" [src]="imagePerfil" [alt]="imagePerfil[0].name">
        </div>
        <div class="col-lg-12 mt-4">
          <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
            <div class="modal-body">
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <div class="form-group">
                          <label for="email"><strong>Email</strong></label>
                          <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="form-group">
                          <label for="name"><strong>Nombre</strong></label>
                          <input formControlName="name" type="text" class="form-control" id="name" placeholder="Nombre">
                        </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="form-group">
                        <label for="url_image"><strong>Imagen Perfil</strong></label>
                        <button class="btn btn-outline-primary-2x btn-selectfile" type="button" placement="top" ngbTooltip="Selecciona un archivo para este campo"
                        (click)="openModal(modelgallery);">Seleccionar</button>
                      </div>
                    </div>
                    <div class="col-lg-12" style="padding: 0 4%;">
                      <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <div class="form-group">
                              <label for="job_name"><strong>Nombre del puesto</strong></label>
                              <input formControlName="job_name" type="text" class="form-control" id="job_name" placeholder="Nombre del puesto">
                            </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="password"><strong>Password</strong></label>
                            <input type="password" class="form-control" id="password" placeholder="Password">
                          </div>
                        </div>
                        <div class="col-lg-4 col-lg-4 align-items-center d-flex pt-2 row justify-content-center">
                          <button (click)="updatePassword();" type="button" class="btn btn-outline-danger row justify-content-center">Actualizar contraseña</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-2">
                      <div class="form-group min-height_image">
                        <img class="col-lg-12" [src]="image_vehiculo" alt="images_mailing">
                        <label class="col-lg-12 text-center mt-2 text-gray2 position_label" for="images_mailing">{{ image_vehiculo_name }}</label>
                      </div>
                    </div>
                    <div class="col-lg-12 text-center mt-4 mb-4">
                      <button type="submit" class="btn btn-dark mt-4">Actualizar perfil</button>
                    </div>
                  </div>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</div>

<ng-template #modelgallery let-modal id="modal_gallery">
  <div class="modal-header">
    <h4 class="modal-title">Gallery</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-gallery (onClear)="onSelectedProduct($event)"></app-gallery>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<!-- Container-fluid Ends-->
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

let roleUser = 2;
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	//public roleUser: any = 1;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	/*if(roleUser == 1){

	}*/
	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/welcome', title: 'Dashboard', icon: 'home', type: 'link'
		},
		{
			path: '/dashboard/usuarios', title: 'Usuarios', icon: 'user', type: 'link'
		},
		{
			path: '/dashboard/respuestas', title: 'Respuestas', icon: 'clipboard', type: 'link'
		},
		{
			path: '/dashboard/questions', title: 'Preguntas', icon: 'database', type: 'link'
		},
	]
	
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}

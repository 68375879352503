<div class="fondo-astara fondo-astara-gracias" style="min-height: 100vh;">

    <div class="hide">
        <p>Tiempo transcurrido: {{ tiempoFormateado }} segundos</p>
    </div>
    
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="titulo-pregunta">
                    <img class="w-full-img-100" src="assets/img_astara/common/titular-pregunta@2x.png" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="container gracias">
        <div class="row">
            <div class="col-md-6">
                <div class="mensaje-gracias">
                    <h1>¡Gracias por participar!</h1>
                    <p>No hay más preguntas por responder. <span>¡Continúa atento!</span></p>
                    <p style="font-size: 1.2rem;"><strong>Redirigiendo al home en {{ timeQuestion }} segundos.</strong></p>
                </div>
                <a class="boton-enviar jugar salir" href="javascript:void(0)" (click)="authService.SignOut()">Salir</a>
            </div>
            <div class="col-md-6">
                <div class="persona-imagen">
                    <img class="w-full-img-100" src="assets/img_astara/common/persona@2x.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
//import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { DecimalPipe } from '@angular/common';
import { NgApexchartsModule } from "ng-apexcharts";

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartistModule } from 'ng-chartist';
import { NgChartsModule } from 'ng2-charts';
import { CountToModule } from 'angular-count-to';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { PerfilComponent } from './components/perfil/perfil.component';
// AoT requires an exported function for factories

/*  Galeries */
//import { Ng2FileDropModule }  from 'ng2-file-drop';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

/*  Calendario  */
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileSaverModule } from 'ngx-filesaver';

/**Declaración de componentes ASTARA */
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { AsesoresComponent } from './components/asesores/asesores.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { ContentComponent } from './components/trivia/content/content.component';
import { ConteoComponent } from './components/trivia/conteo/conteo.component';
import { PreguntasComponent } from './components/trivia/preguntas/preguntas.component';
import { PreguntasComponent as DashboardPreguntasComponent } from './components/preguntas/preguntas.component';
import { RespuestaComponent } from './components/trivia/respuesta/respuesta.component';
import { GraciasComponent } from './components/trivia/gracias/gracias.component';
import { HomeComponent } from './components/trivia/home/home.component';
import { RespuestasComponent } from './components/respuestas/respuestas.component';

 const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
   maxFilesize: 50,
 };
 
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
   suppressScrollX: false,
   wheelPropagation: false
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PerfilComponent,
    //TipoCambioComponent

    //declaracipon de componentes astara
    UsuariosComponent,
    AsesoresComponent,
    ConteoComponent,
    PreguntasComponent,
    DashboardPreguntasComponent,
    GraciasComponent,
    RespuestaComponent,
    ContentComponent,
    HomeComponent,
    RespuestasComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    //librerias
    CKEditorModule,
    AngularEditorModule,
    ChartistModule,
    NgChartsModule,
    CountToModule,
    NgxChartsModule,
    Ng2GoogleChartsModule,
    AutocompleteLibModule,
    IvyCarouselModule,
    //rutas
    //aboutme
    CommonModule,
    ChartistModule,
    NgChartsModule,
    CountToModule,
    ChartistModule,
    NgChartsModule,
    CountToModule,
    //GalleryComponent,
    NgxChartsModule,
    DragulaModule,
    FileUploadModule,
    DropzoneModule,
    PerfectScrollbarModule,
    NgbModule,
    //calendario
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FlatpickrModule.forRoot(),
    //Ng2FileDropModule,
    LightboxModule,
    GalleryModule,
    NgxPaginationModule,
    FileSaverModule,
    //NgbPaginationModule
  ],
  providers: [
    NgbActiveModal,
    AuthService, AdminGuard, SecureInnerPagesGuard, CookieService, DecimalPipe,
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
  ],
  bootstrap: [AppComponent],
  schemas: [ 
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreguntasService, AnswersService } from '../../../__servicios/service.index';
import { BaseComponent } from '../../../base.component';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var require; const Swal = require('sweetalert2');

import * as $ from 'jquery';
import '@fancyapps/fancybox';
declare global {
  interface JQuery {
      printArea(): void;
  }
}

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.scss']
})
export class PreguntasComponent extends BaseComponent implements OnInit {
  arr_preguntas: any = [];
  index_currentItem: number = 1;
  currentIndex: number = 0;
  currentGroup: any[];
  currentItem: any;
  innerIndex: number = 0;
  
  timeQuestion: string = '';
  isModalOpenError = false;
  isModalOpenSuccess = false;
  question_trivia: string = '';
  optionsTrivia: any;
  orden_asignado: any = '';
  selectedOption: any = '';
  valorPista: string = '';
  /**Question 1 */
  validate_1: boolean = false;
  score: any;
  attempts: any = 0;

  letras = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'];
  selectedOptionIndex: number = 50;
  enviado_api: boolean = false;
  state_send: boolean = true;


  constructor(public _service: PreguntasService, public _service_answers: AnswersService, public router: Router) { 
    super(_service);
  }

  openModalError() {
    this.isModalOpenError = true;
  }
  closeModalError() {
    this.isModalOpenError = false;
  }
  openModalSuccess() {
    this.isModalOpenSuccess = true;
  }
  closeModalSuccess() {
    this.isModalOpenSuccess = false;
  }
  
  ngOnInit(): void {
    $('#valorPista').hide();
    /*$('[data-fancybox]').fancybox({
      modal: true
    });*/
    this.getQuestion();
    this.startTimer();
  }
  
  startTimer() {
    const duration = 3600; // Duración del cronómetro en segundos
    const endTime = new Date().getTime() + duration * 1000; // Tiempo de finalización del cronómetro

    // Función que se ejecutará cada segundo
    const interval = setInterval(() => {
      const currentTime = new Date().getTime(); // Tiempo actual
      const remainingTime = Math.max(0, endTime - currentTime); // Tiempo restante en milisegundos

      // Obtener minutos y segundos del tiempo restante
      const minutes = Math.floor(remainingTime / 1000 / 60);
      const seconds = Math.floor((remainingTime / 1000) % 60);

      // Mostrar el tiempo restante en el formato deseado
      this.timeQuestion = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      // Validar si el minuto ha terminado
      if (remainingTime === 0) {
        clearInterval(interval); // Detener el cronómetro
        console.log('¡El minuto ha terminado!');
      }
    }, 1000); // Intervalo de ejecución cada segundo
  }

  hasNextItem(): boolean {
    const currentGroup = this.arr_preguntas[this.currentIndex];
  
    if (currentGroup && this.innerIndex < currentGroup.length - 1) {
      return true;
    } else if (this.currentIndex < this.arr_preguntas.length - 1) {
      return true;
    } else {
      console.log('No hay más preguntas');
      this.router.navigate(['/trivia/gracias']); 
      return false;
    }
  }

  nextItem() {
    this.selectedOptionIndex = 50; this.score = 0; this.attempts = 0;
    this.index_currentItem++;

    if (this.currentGroup.length > 1) {
      const nextIndex = this.currentGroup.indexOf(this.currentItem) + 1;

      if (nextIndex < this.currentGroup.length) {
        this.currentItem = this.currentGroup[nextIndex];
        //console.log('nextItem 134 -->', this.currentGroup.length, nextIndex);
      } else {
        this.currentIndex++;
        this.innerIndex = nextIndex;

        //console.log('nextItem -->', this.currentGroup.length, nextIndex);

        if (this.currentIndex < this.arr_preguntas.length) {
          this.innerIndex = 0;
          this.currentGroup = this.arr_preguntas[this.currentIndex];
          this.currentItem = this.currentGroup[0];
          
          // Último elemento del último array
          /*
          console.log('currentIndex', this.arr_preguntas.length - 1);
          console.log('innerIndex', this.currentGroup.length - 1);
          if (
            this.currentIndex === this.arr_preguntas.length - 1 &&
            this.innerIndex === this.currentGroup.length - 1
          ) {
            console.log('No hay más preguntas.');
          }*/

        } else {
          this.currentItem = null;
        }
      }
    } else {
      this.currentIndex++;
      //console.log('nextItem this.currentIndex -->', this.currentGroup.length, this.currentIndex);
      if (this.currentIndex < this.arr_preguntas.length) {
        //console.log('else', this.currentIndex);
        this.currentGroup = this.arr_preguntas[this.currentIndex];
        this.currentItem = this.currentGroup[0];
      } else {
        this.currentItem = null;
      }
    }
  }
  
  getQuestion(){
    setTimeout(() => {
      this.arr_preguntas = JSON.parse(localStorage.getItem('question2')).data;
      
      this.currentGroup = this.arr_preguntas[this.currentIndex];
      this.currentItem = this.currentGroup[0];
    }, 500);

    const today = new Date(); const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    
    let formattedDate = `${year}-${month}-${day}`; //formattedDate = '2023-06-14'; //'2023-03-11';

    formattedDate = formattedDate + '_dos';

    /*this._service.trivia(formattedDate).subscribe((res: any) => {
      //console.log('trivia-->', res.data[0])
      if(res.question == true){ 
        this.data_question = res;
        this.question_trivia = res.data[0].question;
        this.optionsTrivia = res.data[0].options;
        this.orden_asignado = res.data[0].orden_asignado;

        //localStorage.setItem('question', JSON.stringify(res));
        /*res.options.forEach(element => {
          console.log(element.option, atob(element.correct).split("_")[0])
        });/
      } else {

      }
    });*/
  }

  getSelectedOption(event, i) {
    this.attempts++;
    console.log('this.attempts', this.attempts);
    this.selectOption(i);
    if(this.attempts == 3){
      $('input[name="groupTrivia"]').prop('disabled', true);
      $('.preguntas1').addClass('item-disabled');
    }
    let valor = this.formateo(event.target.value);
    if(this.validateResponse(valor)){
      
      //this.review();
    }
    //console.log('pregunta2', valor, 'intento ->', this.attempts);
    /*let cod = event.target.value;
    console.log(cod)
    //const value = this.selectedOption; console.log(value);
    let valor = atob(cod); //.split("_")[0]
    console.log(valor)*/
  }
  validateResponse(value){
    if (value == 1) {
      this.openModalSuccess();
      $('input[name="groupTrivia"]').prop('disabled', true);
      $('.preguntas1').addClass('item-disabled');
      return true;
    } else {
      this.openModalError();
      return false;
    }    
  }
  
  review(){
    switch (this.attempts) {
      case 1:
        console.log('puntaje 5');
        this.score = 5;
        break;
      case 2:
        console.log('puntaje 5');
        this.score = 3;
        break;
      case 3:
        console.log('puntaje 1');
        this.score = 1;
        break;
      default:
        console.log('puntaje 0');
        this.score = 0;
        break;
    }
    this.onSubmit();
  }
  onSubmit() {
    //if(this.state_send) {
    this.validate_1 = true;
    this.validateRespuesta();
    //}
  }
  validateRespuesta(){
    
      let data = {
        id_user: this.dataLogin.user.id,
        id_question: this.currentItem.id,
        start_time: this.dataLogin.login.created_at.split(' ')[1],
        end_time: 'total de permanencia ' + this.getHoutTotal('01:00:00', this.tiempoFormateado),
        time: this.getHoutTotal('00:01:00', '00:' + this.timeQuestion),
        score: this.score,
        state: 1
      }
      this._service_answers.save(data).subscribe((res: any) => {
        console.log(res);
        if (res.state == 1) { 
          console.log('enviado');
          this.nextItem();
          this.hasNextItem();
          /*enviado2 = true;
          if(enviado1 && enviado2) {
            this.router.navigate(['/trivia/gracias']); 
          }*/
        } else { this.alertError('error'); }
      });
  }
  getHoutTotal(inicial, final) {
    // Tiempo inicial: 01:00:00
    const startTime = inicial;
    const startTimeParts = startTime.split(':'); // Dividir el tiempo en horas, minutos y segundos
    const startHours = parseInt(startTimeParts[0]);
    const startMinutes = parseInt(startTimeParts[1]);
    const startSeconds = parseInt(startTimeParts[2]);

    // Tiempo a restar: 00:58:05 CRONÓMETRO
    const subtractTime = final;
    const subtractTimeParts = subtractTime.split(':'); // Dividir el tiempo en horas, minutos y segundos
    const subtractHours = parseInt(subtractTimeParts[0]);
    const subtractMinutes = parseInt(subtractTimeParts[1]);
    const subtractSeconds = parseInt(subtractTimeParts[2]);

    // Calcular la diferencia de tiempo
    let resultHours = startHours - subtractHours;
    let resultMinutes = startMinutes - subtractMinutes;
    let resultSeconds = startSeconds - subtractSeconds;

    // Ajustar los valores si los segundos o minutos son negativos
    if (resultSeconds < 0) {
      resultSeconds += 60;
      resultMinutes--;
    }

    if (resultMinutes < 0) {
      resultMinutes += 60;
      resultHours--;
    }

    // Formatear los resultados con dos dígitos
    const formattedHours = resultHours.toString().padStart(2, '0');
    const formattedMinutes = resultMinutes.toString().padStart(2, '0');
    const formattedSeconds = resultSeconds.toString().padStart(2, '0');

    // Resultado final
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  showPista(){
    $('#valorPista').toggle();
    this.valorPista = atob(this.data_question.data[0].help);
  }

  formateo(code){
    code = code.split("--")[1];
    let valor = atob(code).split("_")[0];
    return valor;
  }

  textoNumber(number) {
    var unidades = ['cero', 'primer', 'segundo', 'tercer', 'cuarto', 'quinto'];
    if (number >= 0 && number < unidades.length) {
      return unidades[number];
    } else {
      return 'Número fuera de rango';
    }
  }
  selectOption(index: number): void {
    this.selectedOptionIndex = index;
  }
}

import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Location } from '@angular/common';

import { URLS_API } from '../../../constant';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import axios from 'axios';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  public showLoader: boolean = false;
  public showNotUser: boolean = false;
  userData: any; // Save logged in user data
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,  // NgZone service to remove outside scope warning
    private location: Location
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      //console.log('user -->', JSON.parse(localStorage.getItem('user')));
      //console.log('user -->', user);
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }
  // Sign in with email/password
  SignIn(email: string, password: string) {
    var json_dat = {
      "email": email,
      "password": password
    }
    var d = new Date();
    localStorage.setItem('cronometro', JSON.stringify(d.setHours(d.getHours()+1)));

    return axios.post(URLS_API.login, json_dat)
      //this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
      this.showLoader = true;
      //if(result.data.success == true){
        console.log('auth --> SignIn', result.data);
        if (result.data) {
          this.userData = result.data;
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user')!);
        } else {
          localStorage.setItem('user', 'null');
          JSON.parse(localStorage.getItem('user')!);
        }
        if(result.data.state_login){
          if(result.data.user.email == 'admin_trivia@kia.com.pe'){
            this.router.navigate(['/dashboard/welcome']);
          } else {
            this.router.navigate(['/trivia/welcome']);
          }
        } else {
          console.log(result.data.state_login)
          window.location.reload();
          //window.location.href = '/#/login';
        }
      //}
      /*this.ngZone.run(() => {
        this.showLoader = true;
        this.router.navigate(['/dashboard/welcome']);
      });*/
      //this.SetUserData(result.data);


    })
    .catch((error) => {
      this.showLoader = false;
      this.showNotUser = true;
      if (error.response && error.response.status === 401) {
        // Realizar acciones específicas para un error de autenticación
        console.log('Error de autenticación (401)');
      } else {
        // Resto del código para otros tipos de errores
        window.alert(error.message);
      }
      //window.alert(error.message);
    });
  }
  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    console.log('Check your isLoggedIn');
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {

        this.ngZone.run(() => {
          this.router.navigate(['/trivia/welcome']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    console.log('efeferferf ----------', user);
    /**
      email 
            test@gmail.com
      password  
            test123
    */
   
    /**
     "success": true,
    "data": {
        "token": "21|f3a6wSwWZ5VV3rgGMus39L4gbeh83Wlpw5cMDnL5",
        "name": "Admin",
        "email": "admin@codestation.com"
    },
    "message": "User login successfully."
     */
            var respuesta = {
              "__zone_symbol__state": true,
              "__zone_symbol__value": undefined
            }
    /*const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.data}`
    );
    const userData: User = {
      uid: user.data.token,
      email: user.data.email,
      displayName: user.data.name,
      photoURL: user.data.email,
      emailVerified: user.data.email,
    };
    var ferferf = userRef.set(userData, {
      merge: true,
    });
    */
    return respuesta;
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.clear();
      window.localStorage.clear();
      this.showLoader = false;
      this.router.navigate(['/login']);
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base.component';
import { PreguntasService } from '../../../__servicios/service.index';
import { AuthService } from '../../../shared/services/firebase/auth.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-gracias',
  templateUrl: './gracias.component.html',
  styleUrls: ['./gracias.component.scss']
})
export class GraciasComponent extends BaseComponent implements OnInit {
  timeQuestion: string = '10';

  constructor(public _service:PreguntasService, public authService: AuthService, 
    public router: Router) {
    super(_service); 
    /*localStorage.removeItem("user");
    localStorage.clear();
    window.localStorage.clear();*/
    setTimeout(() => {
    }, 5000);
  }

  
  startTimer() {
    const duration = 12; // Duración del cronómetro en segundos
    const endTime = new Date().getTime() + duration * 1000; // Tiempo de finalización del cronómetro

    // Función que se ejecutará cada segundo
    const interval = setInterval(() => {
      const currentTime = new Date().getTime(); // Tiempo actual
      const remainingTime = Math.max(0, endTime - currentTime); // Tiempo restante en milisegundos

      // Obtener minutos y segundos del tiempo restante
      const minutes = Math.floor(remainingTime / 1000 / 60);
      const seconds = Math.floor((remainingTime / 1000) % 60);

      // Mostrar el tiempo restante en el formato deseado
      //this.timeQuestion = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      this.timeQuestion = `${seconds.toString().padStart(2, '0')}`;
      // Validar si el minuto ha terminado
      if (remainingTime === 0) {
        clearInterval(interval); // Detener el cronómetro
        console.log('¡El tiempo se ha terminado!');
        //this.router.navigate(['/login']);
        this.authService.SignOut();
      }
    }, 1000); // Intervalo de ejecución cada segundo
  }

  ngOnInit(): void {
    this.startTimer();
  }

}

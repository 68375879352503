import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base.component';
import { PreguntasService } from '../../../__servicios/service.index';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  constructor(public router: Router, public service: PreguntasService) { 
    super(service);
  }

  ngOnInit(): void {
  }
  
  quieroJugar(){
    this.router.navigate(['/trivia/conteo']);
  }
}

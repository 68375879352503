import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, Sanitizer, Injectable, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var require; const Swal = require('sweetalert2');
import * as feather from 'feather-icons';
import { FileUploader } from 'ng2-file-upload';

import {  QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { BaseComponent } from '../../base.component';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { AsesoresService } from '../../__servicios/service.index';
import { FormControl, AbstractControl } from '@angular/forms';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


//const URL_SEND = 'http://127.0.0.1:8000/api/upload-file-user';
const URL_SEND = 'https://httpbin.org/post';

@Injectable()
@Component({
  selector: 'app-asesores',
  templateUrl: './asesores.component.html',
  styleUrls: ['./asesores.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //imports: [ GalleryComponent]
})
export class AsesoresComponent extends BaseComponent implements OnInit {
  @Input('icon') public icon;
  @Input() public imagePerfilUser: any;
  imgSrcPerfil = 'https://staging.kia.com.pe/systemv1/cms/assets/img/image_default.jpg';
  title_form = 'Registrar asesor';
  text_btn = 'Registrar';
  arrAsesores: any;
  showUpPass: boolean = false;
  valueUpdate: any;
  array_vehi: any;

  public selected = [];
  public active1 = 1;

  public searchText;
  total$: Observable<number>;
  closeResult: string;

  validationForm: FormGroup;
  /*CREATE TABLE `asesores` (
  `cod_asesor` varchar(20) NOT NULL,
  `nom_asesor` varchar(100) DEFAULT NULL,
  `ape_asesor` varchar(100) DEFAULT NULL,
  `tip_asesor` varchar(100) DEFAULT NULL,
  `contraseña` varchar(100) DEFAULT NULL,
  `state` tinyint(1) DEFAULT NULL,
  `created_at` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP,
  `updated_at*/
  form: FormGroup = new FormGroup({
    cod_asesor: new FormControl(''),
    nom_asesor: new FormControl(''),
    ape_asesor: new FormControl(''),
    tip_asesor: new FormControl(''),
    email: new FormControl(''),
    numero: new FormControl(''),
    state: new FormControl(''),
  });


  constructor(public _service: AsesoresService, private dom: Sanitizer,
    config: NgbModalConfig, public modal: NgbActiveModal, private modalService: NgbModal, 
              private fb: FormBuilder, private cd: ChangeDetectorRef) {
    super(_service);
    config.backdrop = 'static';
    config.keyboard = false;
  }

  
  readExcelFile(file: any) {
    const fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log('readExcelFile', data); // Display the imported data in console
      /*this._service.importar({'data': data}).subscribe((res: any) => {
        if (res.state == 1) { this.alertOk('Importado'); } else { this.alertError('importado'); }
      });*/
    };
    fileReader.readAsBinaryString(file);
    
    setTimeout(() => { 
      this.getItems();
      this.clearInputs();
    }, 1500);
  }
  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.readExcelFile(file);
  }
  
  exportAsExcelFile() {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tableItemsExport);
    const workbook: XLSX.WorkBook = { Sheets: { 'Asesores': worksheet }, SheetNames: ['Asesores'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    var rightNow = new Date(); var date = rightNow.toISOString().slice(0,10).replace(/-/g,"");
    this.saveAsExcelFile(excelBuffer, 'Asesores_' + date + '-' + rightNow.getHours() + rightNow.getMinutes());
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  public uploader: FileUploader = new FileUploader({
    url: URL_SEND,
    isHTML5: true,
    authToken: "Bearer " + this.dataUsToken,
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  verifyUploader(){ let urlImage= '';
    setTimeout(() => {
      urlImage = URL.createObjectURL(this.uploader.queue[0].file.rawFile as any);
      urlImage.replace("unsafe:", "");
      console.log('urlImage', urlImage);
      this.itemFile(urlImage)
      this.imgSrcPerfil = urlImage;
    }, 1000);
  }
  itemFile(item){ //item?._file
    //console.log('itemFile', item.some);
    //this.imgSrcPerfil = URL.createObjectURL(item?.some);
    console.log(item);
    item.replace("unsafe:", "");
   $('#imagePreview').attr('src', item);
  }

  /*  search */
  onChangeFiltro(value){
    this.atributo_search = value;
    this.disabled_insearch = false;
  }
  inputSearch(event){
    this.value_search = event.target.value;
    this.searchSearch();
  }
  ascDescFilter(event){
    this.orden_search = event.target.value;
    this.searchSearch();
  }
  searchSearch() {
    if(this.atributo_search == 'cod_asesor'){
      this.params = {
        'cod_asesor': this.value_search
      };
    }  else if(this.atributo_search == 'nom_asesor'){
      this.params = {
        'nom_asesor': this.value_search
      };
    } else if(this.atributo_search == 'numero'){
      this.params = {
        'numero': this.value_search
      };
    }  else if(this.atributo_search == 'ape_asesor'){
      this.params = {
        'ape_asesor': this.value_search
      };
    }  else if(this.atributo_search == 'tip_asesor'){
      this.params = {
        'tip_asesor': this.value_search
      };
    } else if(this.atributo_search == 'state'){
      this.params = {
        'state': this.value_search
      };
    } else if(this.atributo_search == 'created_at'){
      this.params = {
        'created_at': this.value_search
      };
    }
    //tipos de cago
    this._service.search(this.params, this.orden_search).subscribe((res: any) => {
      this.tableItemsExport = res;
    });
    this._service.search2(this.params, this.pageSize, this.orden_search, this.page).subscribe((res: any) => {
      console.log(res);
      this.tableItems = res.data;
      this.allDataNew = res;
      this.premiumData = res;
      this.pagination = res;
      this.collectionSize = res.total;
      
      const totalPages = Math.ceil(res.total / this.pageSize);
      this.pagination = {
        current_page: this.page,
        last_page: totalPages,
        from: (this.page - 1) * this.pageSize + 1,
        to: Math.min(this.page * this.pageSize, this.collectionSize),
        per_page: this.pageSize,
        total: res.total
      };
    });
    this.getPremiumData2();
  }
  btn_search(){
    $('#select_filtros').val('');
    this.params = [];
    $('#input_search').val(''); $('#select_params').val(''); $('#select_orden').val('');
    this.disabled_insearch = true;
    this.getItems();
  }
  /*  search */

  exportData(){
    //(jsonData, nameFile, nameBook)
    this.exportarExcel(this.tableItems, 'DataUsuarios', 'Versiones');
  }
  
  initForm() {
    this.form = this.fb.group({
      cod_asesor: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      ape_asesor: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      nom_asesor: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      tip_asesor: [''],
      numero: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      email: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  public onDelete(selected) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons.fire({
      code: '¿Estás seguro?',
      text: "Eliminar este elemento",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-astara'
      },
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this._service.delete(selected).subscribe((res: any) => {
          if (res.state == 1) { this.alertOk('Eliminado'); } else { this.alertError('eliminado'); }
        });
        setTimeout(() => { 
          this.getItems();
          this.clearInputs();
        }, 1500);
        /*swalWithBootstrapButtons.fire(
          '¡Eliminado!',
          'El elemento ha sido eliminado correctamente.',
          'success'
        )*/
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'La acción ha sido cancelada.',
          'error'
        )
      }
    })
  }

  edit(item){
    this.title_form = 'Editar asesor';
    this.text_btn = 'Guardar';
    this.showUpPass = true;
    this.valueUpdate = item;
    console.log('edit --', item);
    this.form = this.fb.group({
      cod_asesor: [(item.cod_asesor) ? item.cod_asesor : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      ape_asesor: [(item.ape_asesor) ? item.ape_asesor : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      nom_asesor: [(item.nom_asesor) ? item.nom_asesor : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      tip_asesor: [(item.tip_asesor) ? item.tip_asesor : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      email: [(item.email) ? item.email : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      numero: [(item.numero) ? item.numero : '', Validators.compose([Validators.required, Validators.minLength(1)])],
      state: [(item.state) ? item.state : '', Validators.compose([Validators.required, Validators.minLength(1)])],
    });
  }

  clearInputs(){
    this.title_form = 'Registrar asesor';
    this.text_btn = 'Registrar';
    this.form = this.fb.group({
      cod_asesor: [''],
      ape_asesor: [''],
      nom_asesor: [''],
      tip_asesor: [''],
      email: [''],
      state: [''],
      numero: [''],
    });
  }

  ngOnInit() {
    this.initForm();
    this.getItems();
    setTimeout(() => {
      feather.replace();
    });

    setTimeout(() => { 
    }, 1500);

  }

  getItems(){
    this._service.getItems('desc').subscribe((res: any) => {
      this.tableItemsExport = res;
    });
    this._service.getItems2(this.pageSize, 'desc', this.page).subscribe((res: any) => {
      console.log('getItems', res);
      this.allDataNew = res;
      this.tableItems = res.data;
      this.premiumData = res;
      this.pagination = res;
      this.collectionSize = res.total;

      const totalPages = Math.ceil(res.total / this.pageSize);
      this.pagination = {
        current_page: this.page,
        last_page: totalPages,
        from: (this.page - 1) * this.pageSize + 1,
        to: Math.min(this.page * this.pageSize, this.collectionSize),
        per_page: this.pageSize,
        total: res.total
      };
    });
    setTimeout(() => {
      this.getPremiumData2();
    }, 100);
  }
  
  getPremiumData2(){
    this._service.search2(this.params, this.pageSize, this.orden_search, this.page).subscribe((res: any) => {
      console.log(res);
      this.tableItems = res.data;
      this.allDataNew = res;
      this.premiumData = res;
      this.pagination = res;
      this.collectionSize = res.total;
      
      const totalPages = Math.ceil(res.total / this.pageSize);
      this.pagination = {
        current_page: this.page,
        last_page: totalPages,
        from: (this.page - 1) * this.pageSize + 1,
        to: Math.min(this.page * this.pageSize, this.collectionSize),
        per_page: this.pageSize,
        total: res.total
      };
    });
  }

  onSubmit(form) {
    console.log('onsubmit', form);
    if(!form.cod_asesor){ 
      this._service.save(form).subscribe((res: any) => {
        if (res.state == 1) { this.alertOk('Guardado'); } else { this.alertError('guardado'); }
      });
      this.showUpPass = false;
    } else { 
      this._service.edit(form).subscribe((res: any) => {
        if (res.state == 1) { this.alertOk('Actualizado'); } else { this.alertError('actualizado'); }
      }); 
      this.showUpPass = false;
    }
    setTimeout(() => { 
      this.getItems();
      this.clearInputs();
    }, 1500);
    this.modalService.dismissAll();
  }

  //modal dialog
  actionClose(){
    this.showUpPass = false;
  }

  public beforeChange(e) { }

  open(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop', ariaLabelledBy: 'modal-basic-code', size: 'lg', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  detectChanges() {
    this.getItems();
    this.cd.detectChanges();
  }
}

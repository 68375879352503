import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var require; const Swal = require('sweetalert2');
import * as $ from 'jquery';
import {  QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { BaseComponent } from '../../base.component';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { UsuariosService } from '../../_servicios/service.index';
import { FormControl, AbstractControl } from '@angular/forms';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //imports: [ GalleryComponent]
})
export class PerfilComponent extends BaseComponent implements OnInit {

  title_form = 'Crear usuario';
  showUpPass: boolean = false;
  valueUpdate: any;
  image_vehiculo = 'https://staging.kia.com.pe/systemv1/cms/assets/img/image_default.jpg';
  image_vehiculo_name = 'Nombre del archivo';
  arrayimages: any;
  closeResult: string;
  
  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    email: new FormControl(''),
    name: new FormControl(''),
    role: new FormControl(''),
    job_name: new FormControl(''),
    image: new FormControl(''),
  });

  constructor(public _service: UsuariosService, public router: Router,
     //private com_gal: GalleryComponent, 
    config: NgbModalConfig, public modal: NgbActiveModal, private modalService: NgbModal, 
              private fb: FormBuilder, private cd: ChangeDetectorRef) {
    super(_service);
  }
  
  updatePassword(){
    let valuePass: any = $('#password').val();
    if(valuePass.length > 0 && this.valueUpdate.id != '' || this.valueUpdate.id != null){
      this.valueUpdate.password = valuePass;
      this._service.editPass(this.valueUpdate);
      //updatePass
    }
  }

  onSelectedProduct(image) {
    console.log('onSelectedProduct', image)
    this.arrayimages = image;
    if(image.length > 1){
      Swal.fire({
        icon: 'error',
        code: 'Oops...',
        text: 'Por favor seleccione un solo elemento.',
      });
    } else { 
      this.image_vehiculo = image[0].link_file;
      this.image_vehiculo_name = image[0].name; //nombre del archivo
      //this.image_filecurriculum = '../../assets/img/image_default_file.jpg';
    }
    /*this.modal.close('modelgallery');
    this.modal.close('Close click');
    this.getDismissReason('modelgallery');
    this.modal.dismiss('modelgallery');
    this.modal.dismiss('Close click');*/
    //this.modal.close();
    //this.modal.dismiss('modelgallery')
    //this.modalService.dismissAll();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  ngOnInit() {
    this.initForm();
    this.form = this.dataUsuario;
    this.form = this.fb.group({
      id: [this.dataUsuario.id],
      name: [this.dataUsuario.name],
      email: [this.dataUsuario.email],
      role: [this.dataUsuario.role],
      job_name: [this.dataUsuario.job_name],
    });
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      email: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      role: [''],
      job_name: [''],
      image: [''],
    });
  }

  onSubmit(form) {
    form.image = JSON.stringify(this.arrayimages);
    if(form.image == undefined) { form.image = this.dataUsuario.image; }
    console.log('onsubmit', form);

    let html = '<h6><strong>¿Estás seguro?</strong></h6>' + 
                '<h6>Tu perfil se actualizará </h6>' + 
                '<h1 class="text-danger">' + form.name + '</h1>';
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons.fire({
      html: html,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, actualizar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this._service.edit(form).subscribe((res: any) => {
        if (res.state == 1) { this.alertOk('Actualizado'); } else { this.alertError('actualizado'); }
      });
        swalWithBootstrapButtons.fire(
          'Actualizado!',
          'Tu perfil se actualizó correctamente.',
          'success'
        )
        setTimeout(() => { 
          let html = '<h6><strong>¿' + form.name + ', deseas salir de la sesión?</strong></h6>';
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false,
          })
          swalWithBootstrapButtons.fire({
            html: html,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, salir',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
              localStorage.removeItem("user");
              localStorage.clear();
              window.localStorage.clear();
              this.router.navigate(['/editar-perfil']);
            } else if (
              // Read more about handling dismissals
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Cancelado',
                'La acción ha sido cancelada.',
                'error'
              )
            }
          })
        }, 1000);
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'La acción ha sido cancelada.',
          'error'
        )
      }
    })
  }

  clearInputs(){
    this.title_form = 'Crear usuario';
    this.form = this.fb.group({
      id: [''],
      name: [''],
      email: [''],
      role: [''],
      job_name: [''],
      image: [''],
    });
    this.image_vehiculo = 'https://staging.kia.com.pe/systemv1/cms/assets/img/image_default.jpg';
    this.image_vehiculo_name = 'Nombre de la imagen';
  }

  //modal dialog
  openModal(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop', ariaLabelledBy: 'modal-basic-code', size: 'xxlg', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}

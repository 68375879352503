import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { AsesoresService, PreguntasService } from '../../__servicios/service.index';
import { BaseComponent } from 'src/app/base.component';
type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  showPass: boolean = false;
  public newUser = false;
  fechaproxima: any = '2023-06-12';
  isModalOpenSuccess: boolean = false;
  showNextJueves: boolean = false;
  showNextMartes: boolean = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  closeModalAuth() {
    this.authService.showNotUser = false;
  }
  openModalSuccess() {
    this.isModalOpenSuccess = true;
  }
  closeModalSuccess() {
    this.isModalOpenSuccess = false;
  }
  openModalJueves() {
    this.showNextJueves = true;
  }
  closeModalJueves() {
    this.showNextJueves = false;
  }
  openModalMartes() {
    this.showNextMartes = true;
  }
  closeModalMartes() {
    this.showNextMartes = false;
  }
  constructor(public authService: AuthService, public service: AsesoresService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private router: Router) {
      super(service)
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['Astara_TRIVIA2023', Validators.required]
    });
    
    window.localStorage.clear();
  }

  verificacionEmail(event){
    if(event.value == 'admin_trivia@kia.com.pe'){
      $('#password').val('');
      this.showPass = true;
      this.loginForm.value.password = '';
      console.log(event.value)
    } else {
      this.showPass = false;
      this.loginForm.value.password = 'Astara_TRIVIA2023';
    }
  }

  ngOnInit() {
    console.log('ngOnInit', this.dataLogin);
    if (this.dataLogin != null) {
      if (!this.dataLogin.state_login) {
        const date = new Date(this.dataLogin.question);
        const dateLima = this.convertToLimaTimezone(date);
        const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const dayOfWeek = daysOfWeek[dateLima.getUTCDay()];
        console.log('dayOfWeek ->', dayOfWeek);
        if (dayOfWeek == 'Martes') {
          this.openModalMartes();
        } else {
          this.openModalJueves();
        }
      }
    }
  }
  convertToLimaTimezone(date: Date): Date {
    const utcOffset = -5; // UTC offset for America/Lima (GMT-5)
    const offsetInMinutes = date.getTimezoneOffset();
    const offsetInMillis = offsetInMinutes * 60 * 1000;
    const convertedTimeInMillis = date.getTime() + offsetInMillis + (utcOffset * 60 * 60 * 1000);
    return new Date(convertedTimeInMillis);
  }
  // Simple Login
  login() {
    this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }

}

<div *ngIf="userRoleLevel == 1; else showNotUser" class="container-fluid">
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row" id="usuarios">
            <div class="col-sm-12">
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-header border_astara_p">
                            <div class="row align-items-center">
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-md-2 pl-0">
                                            <div class="form-group mb-0">
                                                <select class="form-select" id="select_filtros" (change)="onChangeFiltro($event.target.value)" required>
                                                    <option value="">Filtrar por</option>
                                                    <option value="nom_asesor">Nombres</option>
                                                    <option value="ape_asesor">Apellidos</option>
                                                    <option value="tip_asesor">Cargo</option>
                                                    <option value="state">Estado</option>
                                                    <option value="created_at">Creación</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 p-0">
                                            <input type="text" class="form-control class_addinput" (change)="inputSearch($event)" id="input_search" placeholder="Buscar..." required [disabled]="disabled_insearch" />
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group mb-0">
                                                <select class="form-select" (change)="ascDescFilter($event)" id="select_orden" required [disabled]="disabled_insearch">
                                                    <option value="">Orden</option>
                                                    <option value="ASC">Ascendente</option>
                                                    <option value="DESC">Descendente</option>
                                                </select>
                                                <div class="invalid-feedback">Selecciona un filtro.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 p-0">
                                            <button class="col-lg-12 btn btn-primary btn-astara-filtros btn-restablecer-astara" placement="top" ngbTooltip="Restablecer los filtros" (click)="btn_search();getItems();"><i data-feather="rotate-ccw"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="row justify-content-right">
                                        <button type="button" class="btn btn-astara btn-astara-filtros mr-2" placement="top" ngbTooltip="Descargar fichero" placement="top" ngbTooltip="Descargar fichero" (click)="exportAsExcelFile();"><i data-feather="arrow-down-circle"></i></button>
                                        <button type="button" class="btn btn-primary btn-astara-filtros mr-2" placement="top" ngbTooltip="Importa un archivo excel en el formato enviado, asegúrate que la data sea correcta."><input type="file" id="inputFileImportHide" (change)="onFileSelected($event)"><i data-feather="arrow-up-circle"></i></button>
                                        <button class="btn btn-success btn-astara-filtros" placement="top" ngbTooltip="Añade un nuevo ítem" (click)="open(content);clearInputs();"><i data-feather="plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Card header end-->
                    </div>
                </div>
                <div class="col-sm-12 col-xl-12">
                    <div class="card border_astara_p">
                        <div class="card-body">
                            <a class="col-lg-12 d-flex" href="javascript:void(0)">
                                <div class="col-lg-3 align-items-center d-flex p-2-0 text-dark fw-bold">Nombres</div>
                                <div class="col-lg-3 align-items-center d-flex p-2-0 text-dark fw-bold">Apellidos</div>
                                <div class="col-lg-2 align-items-center d-flex p-2-0 text-dark fw-bold">Cargo</div>
                                <div class="col-lg-2 align-items-center d-flex p-2-0 pl-5 text-dark fw-bold">Estado</div>
                                <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Editar</div>
                                <div class="col-lg-1 align-items-center d-flex p-2-0 text-dark fw-bold pl-1">Eliminar</div>
                            </a>
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action col-lg-12 d-flex" href="javascript:void(0)" *ngFor="let item of tableItems">
                                    <div class="col-lg-3" (click)="copyText(item.nom_asesor)">{{ item.nom_asesor }}</div>
                                    <div class="col-lg-3" (click)="copyText(item.ape_asesor)">{{ item.ape_asesor }}</div>
                                    <div class="col-lg-2 align-items-center d-flex" (click)="copyText(item.tip_asesor)">{{ item.tip_asesor }}</div>
                                    <div class="col-lg-2 align-items-center d-flex justify-content-center">
                                        <span *ngIf="item.state == 1" class="badge badge-success text-white state_astara">Activo</span>
                                        <span *ngIf="item.state == 0" class="badge badge-danger text-white state_astara">Inactivo</span>
                                    </div>
                                    <div class="col-lg-1 text-center">
                                        <button class="background_border__none" (click)="edit(item);open(content);"><app-feather-icons class="text-primary" icon="settings"></app-feather-icons></button>
                                    </div>
                                    <div class="col-lg-1 text-center">
                                        <button class="background_border__none" (click)="onDelete(item)"><app-feather-icons class="text-danger" icon="trash-2"></app-feather-icons></button>
                                    </div>
                                </a>
                            </div>
                            <div class="d-flex justify-content-between p-2">
                                <ngb-pagination [collectionSize]="pagination.total" [pageSize]="pagination.per_page" [(page)]="page" [maxSize]="5" [rotate]="true"
                                    [ellipses]="false" [boundaryLinks]="true" (pageChange)="getPremiumData2()"></ngb-pagination>
                                <select class="custom-select" style="width: auto; border: none;" [(ngModel)]="pageSize" (ngModelChange)="getPremiumData2();">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="200">200</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.tabla -->
            <!-- Modal user  -->
            <ng-template #content let-modal lg>
                <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                    <div class="modal-header">
                        <div>
                            <h4 class="modal-title mb-0" id="modal-basic-title">Asesor</h4>
                            <p class="mb-0 text-gray fw-light">{{ title_form }}</p>
                        </div>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');actionClose()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="nom_asesor"><strong>Nombres</strong></label>
                                        <input formControlName="nom_asesor" type="text" class="form-control" id="nom_asesor" placeholder="Nombre" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="ape_asesor"><strong>Apellidos</strong></label>
                                        <input formControlName="ape_asesor" type="text" class="form-control" id="ape_asesor" placeholder="Apellidos" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="email"><strong>Correo</strong></label>
                                        <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="numero"><strong>Celular</strong></label>
                                        <input formControlName="numero" type="text" class="form-control" id="numero" placeholder="Celular" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <label for="tip_asesor"><strong>Cargo</strong></label>
                                    <select class="form-select" formControlName="tip_asesor" required>
                                        <option value="">- Seleccionar -</option>
                                        <option value="Asesor de ventas">Asesor de ventas</option>
                                        <option value="Asesor de servicios">Asesor de servicios</option>
                                        <option value="Asesor de entregas">Asesor de entregas</option>
                                        <option value="Vendedor de repuestos">Vendedor de repuestos</option>
                                        <option value="Vendedor de  accesorios">Vendedor de  accesorios</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <label for="state"><strong>Estado</strong></label>
                                    <select class="form-select" formControlName="state" required>
                                        <option value="">- Elige un estado -</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!--button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');clearInputs();actionClose()">Cerrar</button-->
                        <button type="submit" class="btn btn-dark">{{ text_btn }}</button>
                    </div>
                </form>
            </ng-template>
            <!-- Modal user  -->
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>

<!--error-401 start-->
<ng-template #showNotUser>
    <div class="error-wrapper">
        <div class="container">
            <div class="error-heading">
                <h2 class="headline font-primary">401</h2>
            </div>
            <img class="img-100" src="assets/images/other-images/sad.png" alt="" />
            <div class="col-md-8 offset-md-2">
                <p class="sub-content">Lo sentimos, tu nivel de usuario no tiene permiso para este módulo.</p>
            </div>
            <div class="">
                <a [routerLink]="'/dashboard/welcome'" class="btn btn-primary-gradien btn-lg">REGRESAR AL DASHBOARD</a>
            </div>
        </div>
    </div>
</ng-template>
<!--error-401 end-->

import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';
import {URLS_API} from './../constant';
import { Observable, interval } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class BaseService {
    sendToken: string;
    headers = new HttpHeaders();
    headersImage = new HttpHeaders();
    url: any;

    cant__items: any;
    num__pages: any;

    contador = 0;//login

    constructor(public http: HttpClient) {
        var tokenstring = localStorage.getItem('user');
        var data = JSON.parse(tokenstring!)
        
        this.sendToken = data.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${data.token}`
            //'Authorization': `Bearer ${this.sendToken}`
        });
        this.headersImage = new HttpHeaders({
            //'Content-Type': undefined,
            //'Content-Type': 'file',
            'Content-Type': 'multipart/form-data',
            "Authorization": `Bearer ${data.token}`
        });
    }

    _getItems( url, order) {
        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(
            map((res: any) => res));
    }
    
    // Retornamos una promesa pasandole los valores
    protected sendPromise(formData: any, type: any, url: any) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open(type, url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.sendToken);
            xhr.send(formData);
        });
    }
}

<div class="fondo-astara">

    <div class="hide">
        <p>Tiempo transcurrido: {{ tiempoFormateado }} segundos</p>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="titulo-pregunta">
                    <img class="w-full-img-100" src="assets/img_astara/common/titular-pregunta@2x.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="container indicaciones">
        <div class="col-lg-12">
            <div class="row" *ngIf="currentItem">
                <div class="col-md-12">
                    <div class="titulo-item-trivia-pregunta">
                        pregunta {{ index_currentItem }}
                    </div><!--   --- {{ currentItem.orden_asignado }}-->
                </div>
                <div class="col-md-12">
                    <div class="item-trivia-indicacion pregunta">
                        <p style="font-size: 24px;">{{ currentItem.question}}</p>
                    </div>
                    <div class="alternativas alternativas-preguntas">
                        <div class="item-trivia preguntas1"
                            *ngFor="let option of currentItem.options; let i = index"
                            [class.active]="selectedOptionIndex === i">
                            <span class="letra">
                                {{ letras[i] }}
                                <input class="form-check-input groupTrivia" (change)="getSelectedOption($event, i)"
                                    type="radio" name="groupTrivia" [id]="option.id"
                                    [value]="option.id + '--' + option.correct" [checked]="selectedOptionIndex === i">
                            </span><!-- (click)="selectOption(i)"-->
                            <label class="form-check-label cursor-pointer" [for]="option.id">{{ option.option }}</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="legal-pregunta">
                            <p>*Tienes 3 oportunidades para intentarlo, y el puntaje será mayor si logras responder en el 1er
                                intento.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--div *ngIf="!currentItem">
                <p>No hay más items.</p>
            </div-->
        </div>
    </div>
    <div class="container contenido-trivia-boton">
        <div class="row">
            <div class="col-md-12"><!--validateRespuesta()  disabled_btn [disabled]="!" -->
                <a class="boton-enviar jugar responder cursor-pointer" (click)="nextItem(); hasNextItem()">Responder</a>
            </div>
        </div>
    </div>
    
    <div class="col-md-12 p-4 legal-pregunta-footer">
        <div class="separador-footer"></div>
        <div class="text-right">
            <p class="text-right">¿Necesitas reforzar algún concepto? <br>Conoce más en:</p>
            <a target="_blank"
                href="https://www.juntosastaraplatform.pe"><strong>www.juntosastaraplatform.pe</strong></a>
        </div>
    </div>
</div>


<div class="modal" [ngClass]="{'active': isModalOpenError}">
    <div class="modal-content bg-transparent border-none" (click)="closeModalError()">
        <button class="modal-close cursor-pointer" (click)="closeModalError()">&times;</button>
        <img src="assets/img_astara/incorrecto.png" alt="">
        <a class="boton-enviar jugar responder centered-button cursor-pointer" (click)="closeModalError()">OK</a>
    </div>
</div>
<div class="modal" [ngClass]="{'active': isModalOpenSuccess}">
    <div class="modal-content bg-transparent border-none" (click)="closeModalSuccess();review();">
        <button class="modal-close cursor-pointer" (click)="closeModalSuccess()">&times;</button>
        <img src="assets/img_astara/correcto.png" alt="">
        <a class="boton-enviar jugar responder centered-button cursor-pointer"
            (click)="closeModalSuccess();">SIGUIENTE</a>
    </div>
</div>

export class ConfigDB {
	static data = {
		settings: {
		layout_type: 'ltr',
		sidebar: {
		type: 'default',
		body_type: 'default'
		},
		sidebar_setting: 'default-sidebar',
		sidebar_backround: 'dark-sidebar'
	},
	color: {
		layout_version: '',
		color: '',
		primary_color: '',
		secondary_color: '',
		mix_layout: 'light-only'
	},
	router_animation: 'fadeIn'
	}
 }
 /*export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'default',
				body_type: 'default'
			},
			sidebar_setting: 'default-sidebar',
			sidebar_backround: 'dark-sidebar'
		},
		color: {
			layout_version: 'light',
			color: 'color-1',
			primary_color: '#1e172f',
			secondary_color: '#1ea6ec',
			mix_layout: 'default'
		},
		router_animation: 'fadeIn'
	}
}

*/
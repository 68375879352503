import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {CommonModule} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { HomeComponent } from './components/trivia/home/home.component';
import { ContentComponent } from './components/trivia/content/content.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { content } from "./shared/routes/content-routes";
import { full } from './shared/routes/full.routes';
import { AdminGuard } from './shared/guard/admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'trivia/welcome',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  /*{
    path: 'dashboard',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: full
  },*/
  {
    path: 'trivia/welcome',
    component: HomeComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: 'dashboard',
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        component: ContentLayoutComponent,
        canActivate: [AdminGuard],
        children: content
      }
    ]
  },
  /*{
    path: 'dashboard',
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: full
  },*/
  /*{
    path: '',
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: full
  },*/
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    //preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreguntasService } from '../../../__servicios/service.index';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'app-respuesta',
  templateUrl: './respuesta.component.html',
  styleUrls: ['./respuesta.component.scss']
})
export class RespuestaComponent extends BaseComponent implements OnInit {

  constructor(public _service: PreguntasService, public router: Router) { 
    super(_service);
  }

  ngOnInit(): void {
    let question = JSON.parse(localStorage.getItem('question'));
    this.data_question = question;
    /*this.waitForData().then(() => {
      console.log('data_question', this.data_question);
    });*/
  }

  waitForData(): Promise<void> {
    return new Promise((resolve) => {
      const checkData = () => {
        if (this.data_question) {
          resolve();
        } else {
          setTimeout(checkData, 100);
        }
      };
  
      checkData();
    });
  }

  vamos(){
    this.router.navigate(['/trivia/gracias']);
  }
}

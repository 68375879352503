<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-12 row">
        <div class="page-header-left col-lg-6">
          <h3 class="ml-1-7">{{title}}</h3>
        </div>
        <div class="page-header-right col-lg-6">
          <ol class="breadcrumb f-right">
            <li class="breadcrumb-item"><a [routerLink]="'/dashboard/welcome'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              Home</a></li>
            <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
            <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li>
          </ol>
        </div>
      </div>
      <div class="col-lg-6">
        <app-bookmark></app-bookmark>
      </div>
    </div>
  </div>
</div>
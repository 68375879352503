import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { BaseComponent } from 'src/app/base.component';
import { PreguntasService } from '../../../__servicios/service.index';

@Component({
  selector: 'app-conteo',
  templateUrl: './conteo.component.html',
  styleUrls: ['./conteo.component.scss'],
  animations: [
    trigger('zoomIn', [
      state('initial', style({ transform: 'scale(1)' })),
      state('zoomed', style({ transform: 'scale(1.2)' })),
      transition('initial => zoomed', animate('0.5s')),
      transition('zoomed => initial', animate('0.3s'))
    ])
  ]
})
export class ConteoComponent extends BaseComponent implements OnInit {
  countdown: number;
  message: string;
  zoomState: string;
  showCount: boolean = false;
  showQuestion: boolean = true;

  constructor(public router: Router, public _service:PreguntasService) {super(_service); }

  ngOnInit(): void {
    this.startCountdown(3);

    /**this.dataUsuario.id 191*/
    this._service.answers_iduser(this.dataUsuario.id).subscribe((res: any) => {
      console.log('answers_iduser-->', res)
      localStorage.setItem('question2', JSON.stringify(res));
    });

    const today = new Date(); const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    
    let formattedDate = `${year}-${month}-${day}`; //formattedDate = '2023-06-14'; //'2023-03-11';

    formattedDate = formattedDate + '_dos';

    /*this._service.trivia(formattedDate).subscribe((res: any) => {
      console.log('trivia-->', res)
      localStorage.setItem('question2', JSON.stringify(res));
    });*/
  }

  vamos(){
    this.showCount = true;
    this.showQuestion = false;
    this.startCountdown(5);
  }

  startCountdown(seconds: number) {
    this.countdown = seconds;
    const timer = setInterval(() => {
      this.countdown--;
      if (this.countdown < 0) {
        clearInterval(timer);
        this.router.navigate(['/trivia/pregunta']);
        this.message = '¡El conteo ha terminado!';
      }
    }, 1000);
  }

  toggleZoomState() {
    this.zoomState = this.zoomState === 'initial' ? 'zoomed' : 'initial';
  }
}

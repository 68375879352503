import { Subject } from "rxjs/internal/Subject";
import { ViewChild, AfterViewInit, Directive, OnInit } from "@angular/core";
import { timer } from 'rxjs';

import * as $ from 'jquery';
import { Router } from '@angular/router';

//import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var require; const Swal = require('sweetalert2');
import * as XLSX from 'xlsx';

@Directive()
export abstract class BaseComponent  {
    intervalId: any;
    tiempo_trivia: number = 0;
    tiempoFormateado: string = '';
    data_question: any = [];

    hideShowTypeChange: boolean = false;
    public liveDemoVisible2 = false;
    messageExcel: string = "";
    count__array: string = "";
    cant__items = 10;
    num__pages: any;
    
    /*  variables search  */
    atributo_search: string = 'name';
    value_search: any;
    orden_search:string = 'desc';
    disabled_insearch: boolean = true;
    
    imagePerfil: any;
    JobNamePerfil: any;
    nameUserPerfil: any;
    dataLogin: any;
    dataUsuario: any;
    userRoleLevel: any;
    dataUsToken: any;

    ///Filtro de busqueda
    order: string = "";
    sort: string = "";
    params: any;
    data: any;
    arrayBuffer: any;
    
    /**paginate */
    tableItems: any = [];
    tableItemsExport: any = [];
    public pagination: any = {};
    allDataNew: any = [];
    premiumData: any = [];
    source$: any;
    page = 1;
    pageSize = 10;
    collectionSize = 0;
    
    /*Cronómetro */_second = 1000;
    _minute = this._second * 60;
    _hour = this._minute * 60;
    _day = this._hour * 24;
    end: any;
    now: any;
    day: any;
    hours: any;
    minutes: any;
    seconds: any;
    source = timer(0, 1000);
    clock: any;
    tiempoVencido: boolean = true;
    /*cronómetro */

    constructor(public service: any) {
        var tokenstring = localStorage.getItem('user');
        var data = JSON.parse(tokenstring!);
        //console.log('basecomponent', data);
        this.dataLogin = data;
        if(data != null)
        if(data.state_login){
            this.dataUsToken = data.token;
            this.dataUsuario = data.user;
            //this.imagePerfil = JSON.parse(data.user.image);
            this.imagePerfil = 'assets/img_astara/favicon_/apple-icon-precomposed.png';
            this.JobNamePerfil = data.user.cargo_us;
            this.nameUserPerfil = data.user.name;
            this.userRoleLevel = 1;
            //this.userRoleLevel = data.user.role;
            
            let urlActual = window.location.href;
            urlActual = urlActual.replace("http://localhost:4200/#/", "");
            console.log(urlActual);
            
            if( urlActual == 'vehiculos' || urlActual == 'versiones'){
                this.hideShowTypeChange = true;
            }else {
                this.hideShowTypeChange = false;
            }
            //this.startCronometro();
            this.cronometro();
        }
    }

    cronometro() {
         /*  cronómetro */
        this.clock = this.source.subscribe(t => {
            this.now = new Date();
            this.end = new Date(JSON.parse(localStorage.getItem('cronometro'))); //new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');
            this.showDate();
        });
    }
    showDate(){
      let distance = this.end - this.now;
      this.day = Math.floor(distance / this._day);
      this.hours = Math.floor((distance % this._day) / this._hour);
      this.minutes = Math.floor((distance % this._hour) / this._minute);
      this.seconds = Math.floor((distance % this._minute) / this._second);

      this.tiempoFormateado =  this.hours + ':' + this.minutes + ':' + this.seconds;
    }


    startCronometro(): void {
        this.intervalId = setInterval(() => {
          this.tiempo_trivia++;
          this.tiempoFormateado = this.formatTiempo(this.tiempo_trivia);
        }, 1000);
    }

    stopCronometro(): void {
        clearInterval(this.intervalId);
    }

    formatTiempo(tiempo: number): string {
        const horas = Math.floor(tiempo / 3600);
        const minutos = Math.floor((tiempo % 3600) / 60);
        const segundos = tiempo % 60;

        return `${this.twoDigitString(horas)}:${this.twoDigitString(minutos)}:${this.twoDigitString(segundos)}`;
    }

    twoDigitString(number: number): string {
        return number.toString().padStart(2, '0');
    }

    formaterNumber(x, posiciones = 0) {
      var s = x.toString()
      var l = s.length
      var decimalLength = s.indexOf('.') + 1
      var numStr = s.substr(0, decimalLength + posiciones)
      return Number(numStr)
    }

    btn_search(){
    $('#select_filtros').val('');
        this.params = [];
        $('#input_search').val(''); $('#select_params').val(''); $('#select_orden').val('');
        this.disabled_insearch = true;
    }

    getPremiumData(){
        setTimeout(() => { 
            let totalitems = this.tableItems;
            let total_ = this.premiumData;
          
          this.tableItems = total_.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        }, 1500);
    }
    copyText(text: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
    cambiarFormatoFecha(item){ 
        let result = item.replace(/\//g, '-');
        return result; 
    }

    formatFecha(fecha: number) {
        return (fecha < 10) ? "0" + fecha : fecha;
    }

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        return (charCode > 31 && (charCode < 48 || charCode > 57)) ? false : true;
    }
    
    sortJSON(data, key, order) { //ordenar json ascendente o descendente
        if (order == 'ASC'){
            return data.sort(function (a, b) {
                var x = a[key];
                var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        } else {
            return data.sort(function (a, b) {
                var x = a[key];
                var y = b[key];
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            });
        }
    }

    getAllData_(params: any) {
        this.service.getItems(params).subscribe((res: any) => {
        });
    }
    
    //Guardamos los datos del formulario
    save(form: any) {
        console.log('form guardar component -->', form);
        this.service.save(form).subscribe((res: any) => {
            if (res.estado == 1) {
                this.getAllData(this.params);
            } else {
                console.log('error');
            }
        });
    }

    //Editamos el formulario con la url de la imagen
    edit(form: any) {
        console.log('form EDIT component -->', form);
        this.service.edit(form).subscribe((res: any) => {
            if (res.estado == 1) {
                this.getAllData(this.params);
            } else {
            }
        })
    }
    alertOk(texto: string){
        Swal.fire(
            texto + '!',
            'El elemento ha sido ' + texto + ' correctamente.',
            'success'
        )
    }
    alertError(texto: string){
        Swal.fire(
            'Error!',
            'Ocurrió un error al ' + texto,
            'error'
        )
    }
    closeModalTrigger() {
        setTimeout(() => { $(".cerrarModal").trigger('click'); }, 1500);
    }

    SweetAlertConfirm(tittle, text, icon, btn_text) {
        return Swal.fire({
            title: tittle,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#0096B4',
            cancelButtonColor: '#d33',
            confirmButtonText: btn_text
        })
    }
    loading() {
        Swal.fire({
            title: 'Cargando..',
            html: '<p class="cargando"><i class="fa fa-cog fa-5x fa-spin" aria-hidden="true"></i></p>',
            allowOutsideClick: false,
            showConfirmButton: false,
            allowEscapeKey: false
        });
    }

    getAllData(params: any) {
        this.service.getItems(params).subscribe((res: any) => {
            this.tableItems = res;
        });
    }
    
    /*  exportar data   */
    exportarExcel(datadata, nameFile, nameBook){
      const d = new Date();
      let hour = d.getHours() + '-' + d.getMinutes();
      var today = new Date().getFullYear()+'-'+("0"+(new Date().getMonth()+1)).slice(-2)+'-'+("0"+new Date().getDate()).slice(-2)
      var name = nameFile + '_' + today + '_' + hour + '.xlsx';
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(datadata);
  
      XLSX.utils.book_append_sheet(workBook, workSheet, nameBook);
      XLSX.writeFile(workBook, name);
    }

    /**
     * IMPORT FILE
     */
    Upload() {
        let file;
        $('#btn__importar').html('Cargando...');
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
    
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          var jsondataexeIII;
    
          console.log('workbook');
          var data2 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          var excel = {
            'exceldata': data2
          };
    
          //this.loading();
          this.importDataExcel(excel);
    
          /*for (var i = 0; i != (XLSX.utils.sheet_to_json(worksheet, { raw: true })).length; ++i) {//valores del excel
            jsondataexeIII = XLSX.utils.sheet_to_json(worksheet, { raw: true })[i];
            this.saveupdate(jsondataexeIII);
          }*/
        }
        fileReader.readAsArrayBuffer(file);
        //this.modalExcel.hide();
        setInterval(() =>
          //window.location.reload(),
          4000);
    }
    importDataExcel(form: any) {
        this.service.importDataExcel(form).subscribe((res: any) => {
            if (res.estado == 1) {
                this.getAllData(this.params);
                $('#btn__importar').html('Importar');
            } else {
            }
        }, (err: any) => {
            if(err.status==500){
                $('#btn__importar').html('ERROR');
                let mensaje = err.error.message;

                if(mensaje == 'Undefined index: telefono'){
                    this.messageExcel = 'Verificar que los campos de teléfono no estén vacíos.';
                } else if (mensaje == 'Undefined index: num_vin'){
                    this.messageExcel = 'Verificar que los campos de num_vin no estén vacíos.';
                } else if (mensaje == 'Undefined index: email'){
                    this.messageExcel = 'Verificar que los campos de email no estén vacíos.';
                } else if (mensaje == 'Undefined index: color'){
                    this.messageExcel = 'Verificar que los campos de color no estén vacíos.';
                } else if (mensaje == 'Undefined index: nombre') {
                    this.messageExcel = 'Verificar que los campos de nombre no estén vacíos.';
                } else if (mensaje == 'Undefined index: ano_vehi') {
                    this.messageExcel = 'Verificar que los campos de año vehículo no estén vacíos.';
                } else if (mensaje == 'Undefined index: marca') {
                    this.messageExcel = 'Verificar que los campos de marca no estén vacíos.';
                } else if (mensaje == 'Undefined index: modelo') {
                    this.messageExcel = 'Verificar que los campos de modelo no estén vacíos.';
                } else if (mensaje == 'Undefined index: emision_tarjeta_prop') {
                    this.messageExcel = 'Verificar que los campos de emision_tarjeta_prop no estén vacíos.';
                } else if (mensaje == 'Undefined index: ingreso_expediente') {
                    this.messageExcel = 'Verificar que los campos de ingreso_expediente no estén vacíos.';
                } else if (mensaje == 'Undefined index: state') {
                    this.messageExcel = 'Verificar que los campos de state no estén vacíos.';
                } else if (mensaje == 'Undefined index: dni') {
                    this.messageExcel = 'Verificar que los campos de dni no estén vacíos.';
                } else if (mensaje == 'Undefined index: emision_placa_rod'){
                    this.messageExcel = 'Verificar que los campos de emision_placa_rod no estén vacíos.';
                } else if (mensaje == 'Undefined index: cancelacion'){
                    this.messageExcel = 'Verificar que los campos de cancelación no estén vacíos.';
                } else if (mensaje == 'Undefined index: inmatriculacion_aap'){
                    this.messageExcel = 'Verificar que los campos de inmatriculacion_aap no estén vacíos.';
                } else if (mensaje == 'Undefined index: recepcion'){
                    this.messageExcel = 'Verificar que los campos de recepcion no estén vacíos.';
                } else if (mensaje == 'Undefined index: telefono'){
                    this.messageExcel = 'Verificar que los campos de telefono no estén vacíos.';
                } else if (mensaje == 'Undefined index: vehiculo_listo'){
                    this.messageExcel = 'Verificar que los campos de vehiculo_listo no estén vacíos.';
                } else if (mensaje == 'Undefined index: vehi_en'){
                    this.messageExcel = 'Verificar que los campos de vehi_en no estén vacíos.';
                } else {
                    this.messageExcel = 'Verificar las cabeceras del excel: ano_vehi, cancelacion, color, dni, email, emision_placa_rod, emision_tarjeta_prop, ingreso_expediente, inmatriculacion_aap, marca, modelo, nombre num_vin recepcion, state, telefono, vehiculo_listo, vehi_en.';
                }

            }else{
                this.messageExcel = 'Verificar las cabeceras del excel: ano_vehi, cancelacion, color, dni, email, emision_placa_rod, emision_tarjeta_prop, ingreso_expediente, inmatriculacion_aap, marca, modelo, nombre num_vin recepcion, state, telefono, vehiculo_listo, vehi_en.';
                $('#btn__importar').html('ERROR');
            }
        });
    }
}

<div class="body-astara-blue">

    <div class="container-fluid">

        <div class="container contenido-trivia-login">
            <div class="row">
                <div class="login-contenedor">
                    <img class="w-full-img-100 img-trivia-d" src="assets/img_astara/common/login_background_v2.jpg" alt="Astara Login">
                    <img class="w-full-img-100 img-trivia-m" src="assets/img_astara/common/login_background_m_v2.jpg" alt="Astara Login">
                    <div class="logo-astara">
                        <img class="w-full-img-100" src="assets/img_astara/common/logo_astara@3x.png" alt="Astara">
                    </div>
                    <div class="contenido-trivia-form">
                        <h1>¡Juega la trivia!</h1>
                        <p>Participa, diviértete y gana una de las experiencias que tenemos para ti.</p>
                        <form [formGroup]="loginForm" *ngIf="!newUser" action="" id="login-form" class="formulario-contenido-trivia">
                            <div class="container">
                                <div class="col-md-12">
                                    <input (keyup)="verificacionEmail($event.target)" id="username" formControlName="email" type="email" autocomplete="email"
                                            class="form-control" placeholder="INGRESA TU E-MAIL ASTARA">
                                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
										class="text text-danger mt-1">
										Email requerido
									</div>
									<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
										class="text text-danger mt-1">
										Email inválido
									</div>
									<div class="form-group" *ngIf="showPass">
										<label for="password">Contraseña</label>
										<input  type="password" formControlName="password" required="" class="form-control" id="password" placeholder="Ingresa tu contraseña">
										<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
											class="text text-danger mt-1">
											Password is requerido
										</div>
									</div>
                                    <button type="submit" class="boton-enviar cursor-pointer" [class.loader--text]="authService.showLoader"
									[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)">
                                    Enviar
                                    <ion-icon name="send-outline" role="img" class="md hydrated" aria-label="send outline"></ion-icon>
                                    </button>
                                    <div class="help-trivia mt-4">
                                        <p>¿Tienes problemas para acceder? Escríbenos un correo a: <a href="mailto:astara.platform.pe@astara.com">astara.platform.pe@astara.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" [ngClass]="{'active': showNextMartes}">
    <div class="modal-content bg-transparent border-none" (click)="closeModalMartes()">
      <button class="modal-close" (click)="closeModalMartes()">&times;</button>
      <img src="assets/img_astara/gracias-proxima.png" alt="">
      <!--p class="centrado-fecha"><strong>{{ fechaproxima }}</strong></p-->
      <!--a class="boton-enviar jugar responder centered-button" (click)="closeModalMartes();">OK</a-->
    </div>
</div>
<div class="modal" [ngClass]="{'active': showNextJueves}">
    <div class="modal-content bg-transparent border-none" (click)="closeModalJueves()">
      <button class="modal-close" (click)="closeModalJueves()">&times;</button>
      <img src="assets/img_astara/gracias_jueves.png" alt="">
      <!--p class="centrado-fecha"><strong>{{ fechaproxima }}</strong></p-->
      <!--a class="boton-enviar jugar responder centered-button" (click)="closeModalSuccess();">OK</a-->
    </div>
</div>
<div class="modal" [ngClass]="{'active': authService.showNotUser}">
    <div class="modal-content bg-transparent border-none" (click)="closeModalAuth()">
      <button class="modal-close">&times;</button>
      <img src="assets/img_astara/invalido.png" alt="">
    </div>
</div>